import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Alert = ({ alerts }) => {
  useEffect(() => {
    const last = alerts[alerts.length - 1];
    if (last) {
      createAlert(last.msg, last.alertType, last.time);
    }
  }, [alerts]);

  const createAlert = (text, type = "primary", time = 5000) => {
    if (type === "danger") {
      toast.error(text, {
        autoClose: time,
      });
    } else if (type === "primary") {
      toast.info(text, {
        autoClose: time,
      });
    } else if (type === "success") {
      toast.success(text, {
        autoClose: time,
      });
    } else if (type === "warning") {
      toast.warn(text, {
        autoClose: time,
      });
    } else if (type === "dark") {
      toast.dark(text, {
        autoClose: time,
      });
    } else {
      toast(text, { autoClose: time });
    }
  };
  return (
    <Fragment>
      <ToastContainer
        position="bottom-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
      />
    </Fragment>
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
