import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import DemoGif from "./DemoGif";

const Demo = () => {
  return (
    <Fragment>
      <section id="demo">
        <div className="container">
          <div className="row">
            <div className="d-none d-lg-block m-3 col">
              <DemoGif width={"250px"} />
            </div>
            <div className="col-lg-8 text-center">
              <h4 className="section-heading">Give Our Simple System a Try</h4>
              <h2 className="section-heading ">
                <div className="d-block d-sm-none">
                  <a href="sms:+13852904140&body=demo">
                    Text DEMO to (385) 290-4140
                  </a>
                </div>
                <div className="d-none d-sm-block">
                  Text DEMO to (385) 290-4140
                </div>
              </h2>
              <hr className="my-4" />
              <p className="mb-4">
                You will receive a sample text that can be easily customized
                with a few simple clicks.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 mx-auto text-center mt-5">
              <Link
                className="btn btn-primary btn-xl js-scroll-trigger shadow m-3"
                to="/learn-more"
              >
                Learn More
              </Link>
              <Link
                className="btn btn-primary btn-xl js-scroll-trigger shadow m-3"
                to="/register"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Demo;
