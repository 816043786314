import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

import { createTempSign } from "../../actions/sign";
import { setAlert } from "../../actions/alert";
import { prettyPhone } from "../../utlis/pretty";
import { assumptions } from "../../utlis/assumptions";

const TempSign = ({ createTempSign, temp, setAlert }) => {
  const [formData, setFormData] = useState({
    code: "",
    message: "",
    notify: "",
    forward: "",
    address: "",
  });

  const [textNum, setTextNum] = useState({ textCount: 1 });
  const { textCount } = textNum;

  const { code, message, notify, forward, address } = formData;

  let textLen;
  const onChange = (e) => {
    message ? (textLen = Math.ceil(message.length / 160)) : (textLen = 1);
    setTextNum({ ...textNum, textCount: textLen });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const formatPhone = (e) => {
    let phone;
    const input = e.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if (input.length > 6) {
      phone = `(${zip}) ${middle}-${last}`;
    } else if (input.length > 3) {
      phone = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      phone = `(${zip}`;
    }
    setFormData({ ...formData, forward: phone });
  };

  const [redirect, setRedirect] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    if (
      code.length < 3 ||
      code.length > 11 ||
      message.length < 4 ||
      forward.length !== 14
    ) {
      setAlert(
        "Make sure you enter a code, message, and a phone to forward calls to",
        "danger"
      );
    } else {
      createTempSign(code, message, notify, forward, address, temp.number);
      setRedirect(true);
    }
  };

  if (redirect) {
    return <Navigate to={assumptions.afterTempCode} />;
  }

  return (
    <>
      <div
        className="modal  modal-fullscreen fade show"
        tabIndex="-1"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-scrollable modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title display-4 mx-auto">
                Create Your First Code
              </h1>
            </div>
            <div className="modal-body">
              {/* </div> */}
              <div className="row">
                {/* <div className="container"> */}
                {/* <h1 className="display-4"></h1> */}

                <div className="col-md-2"></div>

                <div className="col-md-8">
                  <p>
                    This code will be used on your signs and other marketing
                    materials. Leads will use this code to text or call for
                    info, so make it easy to type. All info but the code can be
                    edited later.
                  </p>

                  <form className="form" onSubmit={(e) => onSubmit(e)}>
                    <h4 className="pt-3">What is the code to be entered?</h4>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="code"
                        name="code"
                        value={code}
                        onChange={(e) => onChange(e)}
                        pattern="[A-Za-z0-9]{1,10}"
                        required
                        style={{ backgroundColor: "#F8FCFF" }}
                        title="Code must be 1-10 characters"
                        placeholder="12345"
                      />

                      <label htmlFor="code">Text Code like 1234</label>
                      <small>
                        We recommend using numbers only for ease of use.
                      </small>
                    </div>
                    <h4 className="mt-5">
                      What do you want the to be auto responded?
                    </h4>
                    <div className="form-floating mb-3">
                      <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        onChange={(e) => onChange(e)}
                        value={formData.message}
                        style={{ backgroundColor: "#F8FCFF", height: "250px" }}
                        placeholder="Thanks for texting..."
                        required
                      />

                      <label htmlFor="message">
                        Message - "Thanks for texting..."
                      </label>
                      <small>
                        <span id="charNum" />
                        Your message will be sent in {textCount} messages
                      </small>
                    </div>
                    <h4 className="mt-5">
                      After someone calls or texts, what email should get a
                      notification?
                    </h4>
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        className="form-control"
                        id="notify"
                        name="notify"
                        value={notify}
                        onChange={(e) => onChange(e)}
                        placeholder="me@myemail.com"
                        style={{ backgroundColor: "#F8FCFF" }}
                      />

                      <label htmlFor="notify">Notify Email Address </label>
                      <small className="mb-3">
                        Optional - enter an email to notify of a call or text.
                        You can add more emails later.
                      </small>
                    </div>
                    <h4 className="pt-5">
                      Who should the call be forwarded to?
                    </h4>
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="forward"
                        name="forward"
                        value={forward}
                        onChange={(e) => formatPhone(e)}
                        placeholder="555-555-5555"
                        style={{ backgroundColor: "#F8FCFF" }}
                        required
                      />

                      <label htmlFor="forward">Call Forward </label>
                      <small className="mb-3">
                        This will only happen if someone calls your number,
                        enters this code (it must be only numbers)
                      </small>
                    </div>
                    <h4 className="pt-5">Enter Tracking</h4>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        value={address}
                        onChange={(e) => onChange(e)}
                        placeholder="123 Main St"
                        style={{ backgroundColor: "#F8FCFF" }}
                      />
                      <label htmlFor="address">Tracking </label>
                      <small className="mb-3">
                        This is just for you. It is to help you know what it was
                        for. For example, which house the code was on when it
                        was text.
                      </small>
                    </div>

                    <div className="d-grid gap-2">
                      <button type="submit" className="btn btn-primary mb-5">
                        Create Code
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TempSign.propTypes = {
  // user: PropTypes.object.isRequired,
  createTempSign: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  lookup: state.lookup,
  temp: state.auth.temp,
});

export default connect(mapStateToProps, { createTempSign, setAlert })(TempSign);
