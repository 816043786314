import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentDots,
  faSign,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";

import sampleSign from "../../img/for-sale-sign.jpg";
import demoText from "../../img/demo-sign-call-removebg-preview.png";
import Demo from "../landing/Demo";

const HowItWorks = () => {
  return (
    <div className="container">
      <h1 className="display-4">How It Works</h1>
      <div className="d-lg-flex justify-content-evenly">
        <div
          className="card border-light mb-3 shadow-sm"
          style={{ maxWidth: "18rem" }}
        >
          <div className="card-body d-grid gap-3">
            <div className="mx-auto">
              <FontAwesomeIcon icon={faCommentDots} size="5x" />
            </div>
            <h5 className="card-title text-center">Create Auto-Response</h5>
            <p className="card-text">
              Create or update the message people will receive when they text
              the custom code to your unique number - like we did when you text
              the code DEMO to (385) 290-4140.
            </p>
          </div>
        </div>
        <div
          className="card border-light mb-3 shadow-sm"
          style={{ maxWidth: "18rem" }}
        >
          <div className="card-body d-grid gap-3">
            <div className="mx-auto">
              <FontAwesomeIcon icon={faSign} size="5x" />
            </div>
            <h5 className="card-title text-center">Market It</h5>
            <p className="card-text">
              Market 'Text for Info' on your real estate signs, flyers, or
              anywhere you want a call to action or a way to collect leads.
            </p>
          </div>
        </div>
        <div
          className="card border-light mb-3 shadow-sm"
          style={{ maxWidth: "18rem" }}
        >
          <div className="card-body d-grid gap-3">
            <div className="mx-auto">
              <FontAwesomeIcon icon={faRedo} size="5x" />
            </div>
            <h5 className="card-title text-center">Update & Repeat</h5>
            <p className="card-text">
              When you sell the house simply move the sign and update the
              auto-responder message.
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8 mx-auto text-center mt-5">
          <Link
            className="btn btn-primary btn-xl js-scroll-trigger shadow m-3"
            to="/register"
          >
            Get Started
          </Link>
        </div>
      </div>

      <div>
        <h1 className="display-6 pt-4">What They See</h1>
        <div className="d-lg-flex justify-content-evenly py-5">
          <div>
            <img
              src={sampleSign}
              className="img-fluid rounded py-3"
              alt="example text for info sign"
              loading="lazy"
            />
          </div>
          <div className="px-5">
            <p className="lead">
              It's a simple process. They see a code to text to your unique
              phone number. A simple call to action will help them send a text.
            </p>
            <p className="lead">
              If they are a serious buyer or curious, a quick text will get them
              all the info they need.
            </p>
          </div>
        </div>
        <div className="d-lg-flex justify-content-evenly py-5">
          <div className="px-5">
            <p className="lead">
              Within seconds they get a response with any info you want to give
              them - pricing, sizing, or even a link to your website.
            </p>
            <p className="lead">
              You can customize and change this message anytime you want. So
              update it if something on the listing changes or completely change
              it to a new house when you sell this one.
            </p>
          </div>
          <div>
            <img
              src={demoText}
              className="img-fluid py-3"
              alt="example text for info response"
              loading="lazy"
            />
          </div>
        </div>
      </div>

      <Demo />
    </div>
  );
};

export default HowItWorks;
