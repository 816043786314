import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import { updatePassword } from "../../actions/auth";

const ResetPassword = ({ updatePassword, match, isAuthenticated }) => {
  const { date, email } = match.params;

  const [formData, setFormData] = useState({
    password: "",
    password2: "",
  });

  const { password, password2 } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert("Passwords do not match", "danger");
    } else {
      updatePassword({ password, email, date });
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Fragment>
      <div style={{ maxWidth: "330px", padding: "15px", margin: "0 auto" }}>
        <h1 className="large text-primary">Reset Password</h1>
        <form className="form" onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <input
              type="password"
              placeholder="New Password"
              className="form-control"
              value={password}
              onChange={(e) => onChange(e)}
              name="password"
              style={{ backgroundColor: "#F8FCFF" }}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              placeholder="Match Password"
              className="form-control"
              value={password2}
              onChange={(e) => onChange(e)}
              name="password2"
              style={{ backgroundColor: "#F8FCFF" }}
              required
            />
          </div>
          <input type="submit" className="btn btn-primary" value="Update" />
        </form>
      </div>
    </Fragment>
  );
};

ResetPassword.propTypes = {
  updatePassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { updatePassword })(ResetPassword);
