import React from "react";
// import { redirect as redirectTo } from "react-router-dom";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import AuthNavbar from "../layout/AuthNavbar";
import Footer from "../layout/Footer";
import PlanProtected from "./PlanProtected";

const PrivateRoute = ({
  children,
  redirect = "/login",
  auth: { isAuthenticated, loading },
  title,
}) => {
  if (!isAuthenticated && !loading) {
    return <Navigate to={redirect} />;
  }
  return (
    <>
      <PlanProtected />
      <Helmet>
        <title>
          {title ? title + " - Simple Sign Calls" : "Simple Sign Calls"}
        </title>
      </Helmet>
      <AuthNavbar authUser={true} />
      <div className="container">
        <span className="m-5"></span>
        {children}
      </div>
      <Footer auth={true} />
    </>
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
