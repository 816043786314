import React, { useState, Fragment, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { StripeProvider, Elements } from "react-stripe-elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  //   faToggleOff,
  //   faToggleOn,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

import CheckoutForm from "./CheckoutForm";
import Spinner from "../layout/Spinner";
import { devPlans } from "../../utlis/plans";
import { assumptions } from "../../utlis/assumptions";

const Checkout = ({ auth: { loading, user } }) => {
  const [theUser, setTheUser] = useState({
    email: "",
    plan: null,
  });
  const [plan, setThePlan] = useState("monthly");
  const trial = assumptions.freeTrial;
  const [plans, setPlans] = useState(devPlans);
  const [stripeApi, setStripeApi] = useState(
    "pk_live_ZdN2N4zWoLp9l6rcXQxYoOsq"
    // "pk_test_Khx7hkeU1SvJXF80fxthA55g"
  );
  useEffect(() => {
    if (window.location.href.includes("simplesigncalls")) {
      setPlans(assumptions.defaultPlans);
      setStripeApi("pk_live_ZdN2N4zWoLp9l6rcXQxYoOsq");
      // setStripeApi("pk_test_Khx7hkeU1SvJXF80fxthA55g");
    } else {
      setPlans(devPlans);
      setStripeApi("pk_live_ZdN2N4zWoLp9l6rcXQxYoOsq");
      // setStripeApi("pk_test_Khx7hkeU1SvJXF80fxthA55g");
    }
  }, []);

  useEffect(() => {
    setTheUser(
      user === null
        ? {}
        : {
            email: user.email,
            plan: user.plan,
          }
    );
  }, [user]);

  const onClick = (time) => {
    setThePlan(time);
  };

  const [monthly, setMonthly] = useState({ price: 15 });
  const [yearly, setYearly] = useState({ price: 150 });
  useEffect(() => {
    const monthyIndex = plans.findIndex(
      (plan) => plan.minCodes === 0 && plan.time === "monthly"
    );
    if (monthyIndex > -1) {
      setMonthly(plans[monthyIndex]);
    }
    const yearlyIndex = plans.findIndex(
      (plan) => plan.minCodes === 0 && plan.time === "yearly"
    );
    if (yearlyIndex > -1) {
      setYearly(plans[yearlyIndex]);
    }
  }, [plans]);
  // const monthly = plans[0].plan; //"plan_DNSuZ60iLzpBqi";
  // const yearly = plans[4].plan; //"plan_DX7QJoGv7jcuoA";

  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    if (theUser.plan) {
      const found = plans.filter((plan) => plan.plan === theUser.plan);
      if (found.length > 0) {
        setRedirect(true);
      }
    }
  }, [theUser, plans]);

  if (redirect) {
    return <Navigate to={assumptions.afterCheckout} />;
  }

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <div className="Checkout">
          {trial && (
            <Fragment>
              <div className="alert alert-primary" role="alert">
                Your Trial Is Over, Select Your Plan
              </div>
            </Fragment>
          )}
          <h1 className="display-4">Select Your Plan</h1>
          <div className="row">
            <div className="col">
              <div className="card h-100">
                <div className="card-body">
                  <ul className="card-text text-center list-unstyled">
                    <li className="mt-2 mb-2">
                      <FontAwesomeIcon icon={faCheck} /> Get your{" "}
                      <strong>unique phone number</strong> in the area code of
                      your choice.
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCheck} /> Create up to 5 codes
                      and update plans when you need more.
                    </li>

                    <li className="mt-2 mb-2">
                      <FontAwesomeIcon icon={faCheck} />{" "}
                      <strong>No contracts</strong>. Upgrade, downgrade, or
                      cancel at anytime
                    </li>
                    {/* <li>Create and manage your codes</li> */}
                    <li className="mt-2 mb-2">
                      <FontAwesomeIcon icon={faCheck} /> Customize the text that
                      is sent when a code is sent to your number
                    </li>
                    <li className="mt-2 mb-2">
                      <FontAwesomeIcon icon={faCheck} /> Customize your email
                      notification when someone calls/texts
                    </li>
                  </ul>
                  <div className="row">
                    <div className="col-sm-6">
                      <div
                        className={
                          plan === "monthly"
                            ? "card border-primary shadow"
                            : "card"
                        }
                        style={{ margin: "1rem" }}
                        onClick={() => onClick("monthly")}
                      >
                        <div className="card-body">
                          <h5 className="card-title">Monthly</h5>
                          <p className="card-text">
                            Pay monthly, cancel anytime. Simple.
                          </p>
                          <button
                            className="btn btn-primary"
                            onClick={() => onClick("monthly")}
                            autoFocus
                          >
                            ${monthly.price} Monthly
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div
                        className={
                          plan === "yearly"
                            ? "card border-primary shadow"
                            : "card"
                        }
                        style={{ margin: "1rem" }}
                        onClick={() => onClick("yearly")}
                      >
                        <div className="card-body">
                          <h5 className="card-title">Yearly</h5>
                          <p className="card-text">
                            Pay yearly, get 2 months free.
                          </p>
                          <button
                            className="btn btn-primary"
                            onClick={() => onClick("yearly")}
                            selected={plan === "yearly" && true}
                          >
                            ${yearly.price} Yearly
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <StripeProvider apiKey={stripeApi}>
                    <Elements>
                      <CheckoutForm
                        planId={plan === "monthly" ? monthly : yearly}
                        planName={plan}
                        email={theUser.email}
                      />
                    </Elements>
                  </StripeProvider>
                  <small className="text-center">
                    90 Day Monney Back Guarantee
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

Checkout.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Checkout);
