import React, { Fragment, useState, useEffect } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateSign, deleteSign } from "../../actions/sign";
import { setAlert } from "../../actions/alert";
import Message from "../messages/Message";
import { findSign, messagesBySign } from "../../utlis/helpers";
import { prettyPhone } from "../../utlis/pretty";
import { addFake } from "../../actions/messages";
// import UpdateCheck from "../stripe/UpdateCheck";

const SignDetail = ({
  updateSign,
  deleteSign,
  dbMessages,
  signs,
  setAlert,
  addFake,
}) => {
  const { id } = useParams();

  const [formData, setFormData] = useState({
    message: "",
    notify: "",
    notify2: "",
    notify3: "",
    notify4: "",
    notify5: "",
    forward: "",
    address: "",
  });

  const [modal, setmodal] = useState({
    show: "none", //block
    theClass: "", //modal fade show
  });

  const [showing, setShowing] = useState({
    show2: true,
    show3: false,
    show4: false,
    show5: false,
  });

  const [numberToShow, setNumberToShow] = useState(10);
  const [deleted, setDeleted] = useState(false);
  const { show, theClass } = modal;

  const [sign, setSign] = useState(null);
  useEffect(() => {
    setSign(findSign(signs, id));
  }, [signs, id]);

  const [dev, setDev] = useState(false);
  useEffect(() => {
    if (window.location.href.includes("simplesigncalls")) {
      setDev(false);
    } else {
      setDev(true);
    }
  }, []);

  useEffect(() => {
    if (sign) {
      setFormData({
        message: !sign.message ? "" : sign.message,
        notify: !sign.notify ? "" : sign.notify,
        notify2: !sign.notify2 ? "" : sign.notify2,
        notify3: !sign.notify3 ? "" : sign.notify3,
        notify4: !sign.notify4 ? "" : sign.notify4,
        notify5: !sign.notify5 ? "" : sign.notify5,
        forward: !sign.forward ? "" : prettyPhone(sign.forward),
        address: !sign.address ? "" : sign.address,
      });
    }
  }, [sign]);

  useEffect(() => {
    if (sign) {
      setShowing({
        notify1: true,
        notify2: !sign.notify2 ? false : true,
        notify3: !sign.notify3 ? false : true,
        notify4: !sign.notify4 ? false : true,
      });
    }
  }, [sign]);

  const [messages, setMessages] = useState([]);
  useEffect(() => {
    if (sign) {
      setMessages(messagesBySign(dbMessages, sign.code));
    }
  }, [sign, dbMessages]);

  const [textNum, setTextNum] = useState({ textCount: 1 });
  const { textCount } = textNum;

  const {
    message,
    notify,
    notify2,
    notify3,
    notify4,
    notify5,
    forward,
    address,
  } = formData;

  let textLen;
  const onChange = (e) => {
    message ? (textLen = Math.ceil(message.length / 160)) : (textLen = 1);
    setTextNum({ ...textNum, textCount: textLen });
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (
      e.target.name.indexOf("notify") > -1 &&
      e.target.value.indexOf("@") > -1
    ) {
      setShowing({ ...showing, [e.target.name]: true });
    }
  };

  const formatPhone = (e) => {
    let phone;
    const input = e.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if (input.length > 6) {
      phone = `(${zip}) ${middle}-${last}`;
    } else if (input.length > 3) {
      phone = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      phone = `(${zip}`;
    }
    setFormData({ ...formData, forward: phone });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!message || message.length < 5 || message.length > 480) {
      setAlert("Message needs to be between 5 and 480 characters", "danger");
    } else {
      updateSign({
        id,
        message,
        notify,
        notify2,
        notify3,
        notify4,
        notify5,
        forward,
        address,
      });
    }
  };

  if (deleted) {
    setAlert("Code deleted!", "success");
    return <Navigate to="/dashboard" />;
  }

  return (
    <Fragment>
      {!sign ? (
        <div className="position-absolute top-50 start-50 translate-middle">
          <h3>Sign Not Found</h3>
          <Link to="/signs">Back to Signs</Link>
        </div>
      ) : (
        <Fragment>
          <div className="row">
            <div className="container">
              <div className="d-flex justify-content-between">
                <p>
                  <Link to="/dashboard">Back to Dashboard</Link>
                </p>
                {dev ? (
                  <div className="grid-2 gap-2">
                    <button
                      className="btn"
                      onClick={() => {
                        addFake(sign.code, "text", sign.phone);
                      }}
                      style={{
                        // position: "absolute",
                        width: "100px",
                        height: "50px",
                        padding: 0,
                        margin: "1px",
                        overflow: "hidden",
                        clip: "rect(0, 0, 0, 0)",
                        whiteSpace: "nowrap",
                        border: 0,
                        background: "white",
                        color: "white",
                      }}
                    >
                      Fake Text
                    </button>
                    <button
                      className="btn"
                      onClick={() => {
                        addFake(sign.code, "call", sign.phone);
                      }}
                      style={{
                        // position: "absolute",
                        width: "100px",
                        height: "50px",
                        padding: 0,
                        margin: "1px",
                        overflow: "hidden",
                        clip: "rect(0, 0, 0, 0)",
                        whiteSpace: "nowrap",
                        border: 0,
                        background: "white",
                        color: "white",
                      }}
                    >
                      Fake Call
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <p className="text-muted" style={{ marginBlockEnd: 0 }}>
                Text
              </p>
              <h1 className="display-4">{sign.code}</h1>
              {sign.phone && (
                <p className="text-muted">to {prettyPhone(sign.phone)}</p>
              )}
              <form className="form" onSubmit={(e) => onSubmit(e)}>
                <div className="form-floating mb-3">
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    style={{ height: "250px", backgroundColor: "#F8FCFF" }}
                    onChange={(e) => onChange(e)}
                    value={formData.message}
                    placeholder="message"
                    required
                  />
                  <label htmlFor="message">Message to send:</label>
                  <small>
                    <span id="charNum" />
                    Your message will be sent in {textCount} messages
                  </small>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="notify"
                    name="notify"
                    value={notify}
                    style={{ backgroundColor: "#F8FCFF" }}
                    onChange={(e) => onChange(e)}
                    placeholder="email@email.com"
                  />
                  <label htmlFor="notify">Notify Email Address</label>
                  <small>
                    Optional: Enter additional email addresses to receive lead
                    notifications.
                  </small>
                </div>
                {showing.notify1 ? (
                  <div
                    className="form-floating mb-3"
                    style={{ display: "block" }}
                  >
                    <input
                      type="email"
                      className="form-control"
                      style={{ backgroundColor: "#F8FCFF" }}
                      id="notify2"
                      name="notify2"
                      value={notify2}
                      onChange={(e) => onChange(e)}
                      placeholder="email@email.com"
                    />
                    <label htmlFor="notify2">Second Notify Email Address</label>
                  </div>
                ) : (
                  <div className="form-group" style={{ display: "none" }} />
                )}
                {showing.notify2 ? (
                  <div
                    className="form-floating mb-3"
                    style={{ display: "block" }}
                  >
                    <input
                      type="email"
                      className="form-control"
                      id="notify3"
                      name="notify3"
                      value={notify3}
                      onChange={(e) => onChange(e)}
                      placeholder="email@email.com"
                      style={{ backgroundColor: "#F8FCFF" }}
                    />
                    <label htmlFor="notify3">Third Notify Email Address</label>
                  </div>
                ) : (
                  <div className="form-group" style={{ display: "none" }} />
                )}
                {showing.notify3 ? (
                  <div
                    className="form-floating mb-3"
                    style={{ display: "block" }}
                  >
                    <input
                      type="email"
                      className="form-control"
                      id="notify4"
                      name="notify4"
                      value={notify4}
                      onChange={(e) => onChange(e)}
                      placeholder="email@email.com"
                      style={{ backgroundColor: "#F8FCFF" }}
                    />
                    <label htmlFor="notify4">Fourth Notify Email Address</label>
                  </div>
                ) : (
                  <div className="form-group" style={{ display: "none" }} />
                )}
                {showing.notify4 ? (
                  <div
                    className="form-floating mb-3"
                    style={{ display: "block" }}
                  >
                    <input
                      type="email"
                      className="form-control"
                      id="notify5"
                      name="notify5"
                      value={notify5}
                      onChange={(e) => onChange(e)}
                      placeholder="email@email.com"
                      style={{ backgroundColor: "#F8FCFF" }}
                    />
                    <label htmlFor="notify5">Fifth Notify Email Address</label>
                  </div>
                ) : (
                  <div className="form-group" style={{ display: "none" }} />
                )}

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="forward"
                    name="forward"
                    value={forward}
                    onChange={(e) => formatPhone(e)}
                    placeholder="555-555-5555"
                    style={{ backgroundColor: "#F8FCFF" }}
                    required
                  />
                  <label htmlFor="forward">Call Forward</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    name="address"
                    value={address}
                    onChange={(e) => onChange(e)}
                    placeholder="123 Main St"
                    style={{ backgroundColor: "#F8FCFF" }}
                  />
                  <label htmlFor="address">Tracking</label>
                </div>

                <div className="d-grid gap-2">
                  <button type="submit" className="btn btn-primary">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
          <hr />
          <h3 className="display-4 mt-5">Messages for Sign {sign.code}</h3>
          <div className="row">
            <div className="w-100">
              <div className="list-group">
                <span className="list-group-item list-group-item-dark disabled">
                  <div className="row">
                    <div className="col-4">
                      <b>Code</b>
                    </div>
                    <div className="col-4">
                      <b>Tracking</b>
                    </div>
                    <div className="col-4">
                      <b>When</b>
                    </div>
                  </div>
                </span>
                {messages.length > 0 ? (
                  messages
                    .slice(0, numberToShow)
                    .map((message) => (
                      <Message key={message._id} message={message} />
                    ))
                ) : (
                  <h3 className="text-center m-5">No Messages Yet.</h3>
                )}
                <Fragment>
                  {messages.length > numberToShow ? (
                    <button
                      className="btn btn-secondary btn-block"
                      onClick={() => {
                        setNumberToShow(numberToShow + 10);
                      }}
                    >
                      Show More
                    </button>
                  ) : (
                    ""
                  )}
                </Fragment>
              </div>
            </div>
          </div>
          <hr />
          <button
            className="btn btn-danger"
            onClick={() => {
              setmodal({ show: "block", theClass: "show" });
            }}
          >
            Delete Code
          </button>
          <div
            className={"modal fade " + theClass}
            tabIndex="-1"
            role="dialog"
            style={{ display: show }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Delete this code?</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setmodal({ show: "none", theClass: "" });
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>Are you sure you want to delete this code?</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      deleteSign(id);
                      setDeleted(true);
                      setmodal({ show: "none", theClass: "" });
                    }}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setmodal({ show: "none", theClass: "" });
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
      {modal.show === "block" && <div className="modal-backdrop fade show" />}
    </Fragment>
  );
};

SignDetail.propTypes = {
  updateSign: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  addFake: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  signs: state.sign.signs,
  dbMessages: state.message.messages,
});

export default connect(mapStateToProps, {
  updateSign,
  setAlert,
  deleteSign,
  addFake,
  // getMessagesByCode,
})(SignDetail);
