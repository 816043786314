import React, { Fragment } from "react";
// import demo from "../../img/demo.gif";
import demo from "../../img/demo.webp";

export default ({ width }) => (
  <Fragment>
    <img
      src={demo}
      style={{ width: width, margin: "auto", display: "block" }}
      alt="Demo"
      loading="lazy"
    />
  </Fragment>
);
