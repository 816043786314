import React from "react";
import { Link } from "react-router-dom";
import Pricing from "../landing/Pricing";

const PricingPage = () => {
  return (
    <div>
      <Pricing />
      <div className="container">
        <h1 className="display-6">What you get</h1>
        <p className="lead">
          Every plan comes with a <b>unique phone number</b> with the area code
          of your choice. So when people text or call for more info, they are
          texting a <b>local phone number</b>. This is your phone number, use it
          on any of your marketing.
        </p>
        <p className="lead">
          Upgrade or downgrade to use more or less codes. Match your sign needs
          to save. And <b>No contract</b>!
        </p>
        <div className="row">
          <div className="col-lg-8 mx-auto text-center mt-5">
            <Link
              className="btn btn-primary btn-xl js-scroll-trigger shadow m-3"
              to="/register"
            >
              Let's Go!
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
