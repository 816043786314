import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import MainLanding from "./components/landing/MainLanding";

import Alert from "./components/layout/Alert";
import PrivateRoute from "./components/routing/PrivateRoute";
import PublicRoute from "./components/routing/PublicRoute";
import ScrollToTop from "./utlis/scrollToTop";

import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utlis/setAuthToken";

import "./App.css";
import GoogleAnalytics from "./components/GoogleAnalytics";

import Dashboard from "./components/dashboard/Dashboard";
import Go from "./components/landingpages/Go";
import Signs from "./components/signs/Signs";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Reset from "./components/auth/Reset";
import SetUpAccount from "./components/auth/SetUpAccount";
import Terms from "./components/legal/Terms";
import Privacy from "./components/legal/Privacy";
import FaqMain from "./components/faq/FaqMain";
import LearnMore from "./components/home/LearnMore";
import HowItWorks from "./components/home/HowItWorks";
import PricingPage from "./components/home/PricingPage";
import SolutionMain from "./components/faq/SolutionMain";
import Solution from "./components/faq/Solution";
import ResetPassword from "./components/auth/ResetPassword";
import SignDetail from "./components/signs/SignDetail";
import Messages from "./components/messages/Messages";
import MessageDetail from "./components/messages/MessageDetail";
import Settings from "./components/settings/Settings";
import LookUp from "./components/phone/LookUp";
import StartTrial from "./components/phone/StartTrial";
import Checkout from "./components/stripe/Checkout";
import EndTrial from "./components/stripe/EndTrial";
import SignCreate from "./components/signs/SignCreate";
import FirstSign from "./components/signs/FirstSign";
import TempSign from "./components/signs/TempSign";
import UpdatePlan from "./components/stripe/UpdatePlan";
import Update from "./components/stripe/Update";
import Feedback from "./components/settings/Feedback";
import AdminDash from "./components/admin/AdminDash";
import AddUser from "./components/admin/AddUser";
import UserInfo from "./components/admin/UserInfo";
import NotFound from "./components/layout/NotFound";
import TestSign from "./components/signs/TestSign";
import ReservePhone from "./components/phone/ReservePhone";
import Checkout2 from "./components/stripe/Checkout2";
if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <div className="mt-5" style={{ minHeight: "100vh" }}>
            <Alert />
            <ScrollToTop />
            <Routes>
              <Route
                path="/"
                element={
                  <PublicRoute title="Log In To Simple Sign Calls">
                    {/* <MainLanding /> */}
                    <Login />
                  </PublicRoute>
                }
              />
              <Route
                path="go/:slug"
                element={
                  <PublicRoute title="Take Control of Your Sign Calls">
                    <Go />
                  </PublicRoute>
                }
              />
              {/* <section className="container" style={{ paddingTop: "5rem" }}> */}
              <Route
                path="register"
                element={
                  <PublicRoute title="Register">
                    <Register />
                  </PublicRoute>
                }
              />
              <Route
                path="login"
                element={
                  <PublicRoute title="Login">
                    <Login />
                  </PublicRoute>
                }
              />
              <Route
                path="reset"
                element={
                  <PublicRoute title="Reset Password">
                    <Reset />
                  </PublicRoute>
                }
              />
              <Route
                path="terms"
                element={
                  <PublicRoute title="Terms & Conditions">
                    <Terms />
                  </PublicRoute>
                }
              />
              <Route
                path="privacy"
                element={
                  <PublicRoute title="Privacy Policy">
                    <Privacy />
                  </PublicRoute>
                }
              />
              <Route
                path="learn-more"
                element={
                  <PublicRoute title="Learn More">
                    <LearnMore />
                  </PublicRoute>
                }
              />
              <Route
                path="how-it-works"
                element={
                  <PublicRoute title="How It Works">
                    <HowItWorks />
                  </PublicRoute>
                }
              />
              <Route
                path="pricing"
                element={
                  <PublicRoute title="Pricing">
                    <PricingPage />
                  </PublicRoute>
                }
              />
              <Route
                path="faq"
                element={
                  <PublicRoute title="Frequently Asked Questions">
                    <FaqMain />
                  </PublicRoute>
                }
              />
              <Route
                path="solutions"
                element={
                  <PublicRoute title="Solutions">
                    <SolutionMain />
                  </PublicRoute>
                }
              />
              <Route
                path="solutions/:slug"
                element={
                  <PublicRoute title="Solution">
                    <Solution />
                  </PublicRoute>
                }
              />
              <Route
                path="reset/:email/:date"
                element={
                  <PublicRoute title="Password Reset Link">
                    <ResetPassword />
                  </PublicRoute>
                }
              />
              <Route
                path="contact-us"
                element={
                  <PublicRoute title="Contact Us">
                    <Feedback />
                  </PublicRoute>
                }
              />

              <Route
                path="dashboard"
                element={
                  <PrivateRoute title="Dashboard">
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="settings"
                element={
                  <PrivateRoute title="Settings">
                    <Settings />
                  </PrivateRoute>
                }
              />
              <Route
                path="feedback"
                element={
                  <PrivateRoute title="Feedback">
                    <Feedback />
                  </PrivateRoute>
                }
              />
              <Route
                path="look-up"
                element={
                  <PrivateRoute title="Look Up">
                    <LookUp />
                  </PrivateRoute>
                }
              />
              <Route
                path="reserve-phone"
                element={
                  <PrivateRoute title="Look Up & Reserve Phone Number">
                    <ReservePhone />
                  </PrivateRoute>
                }
              />
              <Route
                path="create-code"
                element={
                  <PrivateRoute title="Create Your First Code">
                    <TempSign />
                  </PrivateRoute>
                }
              />
              <Route
                path="account-set-up"
                element={
                  <PrivateRoute title="Setting Up Account...">
                    <SetUpAccount />
                  </PrivateRoute>
                }
              />
              <Route
                path="start-trial"
                element={
                  <PrivateRoute title="Start Trial">
                    <StartTrial />
                  </PrivateRoute>
                }
              />
              <Route
                path="first-sign"
                element={
                  <PrivateRoute title="Create Your First Sign Code">
                    <FirstSign />
                  </PrivateRoute>
                }
              />
              <Route
                path="select-plan"
                element={
                  <PrivateRoute title="Checkout">
                    <Checkout2 trial={false} />
                  </PrivateRoute>
                }
              />
              <Route
                path="end-trial"
                element={
                  <PrivateRoute title="End Trial">
                    <EndTrial />
                  </PrivateRoute>
                }
              />
              <Route
                path="update-plan"
                element={
                  <PrivateRoute title="Update Your Plan">
                    <UpdatePlan />
                  </PrivateRoute>
                }
              />

              <Route
                path="update-card"
                element={
                  <PrivateRoute title="Update Your Payment">
                    <Update />
                  </PrivateRoute>
                }
              />
              <Route
                path="signs"
                element={
                  <PrivateRoute title="Signs">
                    <Signs />
                  </PrivateRoute>
                }
              />
              <Route
                path="signs/new"
                element={
                  <PrivateRoute title="Create a New Sign Code">
                    <SignCreate />
                  </PrivateRoute>
                }
              />
              <Route
                path="signs/:id"
                element={
                  <PrivateRoute title="Sign Code Details">
                    <SignDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="test/:id"
                element={
                  <PrivateRoute title="Test Your Code">
                    <TestSign />
                  </PrivateRoute>
                }
              />
              <Route
                path="messages"
                element={
                  <PrivateRoute title="Messages">
                    <Messages />
                  </PrivateRoute>
                }
              />
              <Route
                path="messages/:id"
                element={
                  <PrivateRoute title="Messages Details">
                    <MessageDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="messages/type/:type"
                element={
                  <PrivateRoute title="Messages by Type">
                    <Messages />
                  </PrivateRoute>
                }
              />
              <Route
                path="admin"
                element={
                  <PrivateRoute title="Admin">
                    <AdminDash />
                  </PrivateRoute>
                }
              />
              <Route
                path="admin/user/add"
                element={
                  <PrivateRoute title="Admin">
                    <AddUser />
                  </PrivateRoute>
                }
              />
              <Route
                path="admin/user/:id"
                element={
                  <PrivateRoute title="Admin User Info">
                    <UserInfo />
                  </PrivateRoute>
                }
              />
              <Route
                path="*"
                element={
                  <PublicRoute title="Oops">
                    <NotFound />
                  </PublicRoute>
                }
              />
            </Routes>
            {/* </section> */}
            <GoogleAnalytics />
          </div>
        </BrowserRouter>
      </Provider>
    </>
  );
};

export default App;
