import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import Clickfunnel from "./Clickfunnel";
import SmsTextAutoresponder from "./SmsTextAutoresponder";
import SmsKeywordAutoresponder from "./SmsKeywordAutoresponder";

const Go = () => {
  const { slug } = useParams();

  if (slug === "meet") {
    return (
      <>
        <Helmet>
          <title>Meet Simple Sign Calls</title>
        </Helmet>
        <Clickfunnel />;
      </>
    );
  } else if (slug === "sms-text-autoresponder") {
    return (
      <>
        <Helmet>
          <title>SMS Text Autoresponder - Simple Sign Calls</title>
        </Helmet>
        <SmsTextAutoresponder />;
      </>
    );
  } else if (slug === "sms-keyword-autoresponder") {
    return (
      <>
        <Helmet>
          <title>SMS Keyword Autoresponder - Simple Sign Calls</title>
        </Helmet>
        <SmsKeywordAutoresponder />;
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>Meet Simple Sign Calls</title>
        </Helmet>
        <Clickfunnel />;
      </>
    );
  }
};

Go.propTypes = {};

export default Go;
