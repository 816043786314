import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Pricing = () => {
  const [formData, setFormData] = useState({
    listings: 5,
    price: 15,
    timing: "Monthly",
  });

  let { listings, price, timing } = formData;
  const onChange = (e) => {
    if (e.target.value > 50) {
      if (timing === "Monthly") {
        price = 125;
      } else {
        price = 125 * 10;
      }
    } else if (e.target.value > 20) {
      if (timing === "Monthly") {
        price = 75;
      } else {
        price = 75 * 10;
      }
    } else if (e.target.value > 5) {
      if (timing === "Monthly") {
        price = 40;
      } else {
        price = 40 * 10;
      }
    } else {
      if (timing === "Monthly") {
        price = 15;
      } else {
        price = 15 * 10;
      }
    }
    setFormData({ ...formData, listings: e.target.value, price: price });
  };

  const onClick = () => {
    if (timing === "Monthly") {
      timing = "Yearly";
      price = price * 10;
    } else {
      timing = "Monthly";
      price = price / 10;
    }
    setFormData({ ...formData, timing, price });
  };
  return (
    <Fragment>
      <section id="pricing">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1 className="section-heading display-6">Pricing</h1>
              <hr className="my-4" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-1" />
            <div className="col-5 text-center">
              <h1 className="display-4" id="listingSlider">
                {listings} <br />
                Codes
              </h1>
            </div>
            <div className="col-5 text-center">
              <h1 className="display-4" id="priceSlider">
                ${price}
              </h1>
              {timing} Price
            </div>
            <div className="col-1" />
          </div>
          <div className="row">
            <div className="col-1" />
            <div className="col-10">
              <div className="slidecontainer" style={{ padding: "1em" }}>
                <input
                  type="range"
                  min="1"
                  max="100"
                  value={listings}
                  className="slider"
                  id="myRange"
                  onChange={onChange}
                />
              </div>
              <p className="text-center">
                Slide to your average number of listings. For more than 100,{" "}
                <Link to="/contact-us">contact us</Link>.
              </p>
              <div className="row">
                <div className="col-5 text-end">
                  {timing === "Monthly" ? (
                    <span className="d-block" style={{ fontSize: "1.5em" }}>
                      Monthly{" "}
                    </span>
                  ) : (
                    <span className="d-none" style={{ fontSize: "1.5em" }}>
                      {" "}
                    </span>
                  )}
                </div>
                <div className="col-2 text-center">
                  {timing === "Yearly" ? (
                    <span style={{ cursor: "pointer" }} onClick={onClick}>
                      <FontAwesomeIcon icon={faToggleOn} size="2x" />
                    </span>
                  ) : (
                    <span style={{ cursor: "pointer" }} onClick={onClick}>
                      <FontAwesomeIcon icon={faToggleOff} size="2x" />
                    </span>
                  )}
                </div>
                <div className="col-5 text-start">
                  {timing === "Yearly" ? (
                    <span className="d-block" style={{ fontSize: "1.5em" }}>
                      Yearly{" "}
                    </span>
                  ) : (
                    <span className="d-none" style={{ fontSize: "1.5em" }}>
                      {" "}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-1" />
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Pricing;
