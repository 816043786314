import React, { Fragment } from "react";
import { HashLink as Anchor } from "react-router-hash-link";
import { connect } from "react-redux";
import { faq } from "../../utlis/faq";
import Faq from "./Faq";

const FaqMain = () => {
  const categories = [];
  faq.map(
    (fa) => !categories.includes(fa.category) && categories.push(fa.category)
  );
  return (
    <div className="container">
      <h1 className="display-4">Frequently Asked Questions</h1>
      <p>Jump to:</p>
      <ul className="list-unstyled">
        {categories.map((category) => (
          <Fragment key={category.replace(" ", "-").toLowerCase()}>
            <li>
              <Anchor smooth to={"/faq#" + category.replace(" ", "-")}>
                {category}
              </Anchor>
            </li>
          </Fragment>
        ))}
      </ul>

      {categories.map((cat) => (
        <Fragment key={cat.replace(" ", "-").toLowerCase()}>
          <div id={cat.replace(" ", "-")} className="mt-5">
            <h3>{cat}</h3>
          </div>
          <hr style={{ height: "2px", backgroundColor: "#007BFF" }} />
          <div id="accordion">
            {faq.map((f) => f.category === cat && <Faq key={f.id} faq={f} />)}
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default connect()(FaqMain);
