import React from "react";
// import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";

const PublicRoute = ({ children, title }) => {
  return (
    <>
      <Helmet>
        <title>
          {title ? title + " - Simple Sign Calls" : "Simple Sign Calls"}
        </title>
      </Helmet>
      <Navbar authUser={false} />
      {children}
      <Footer auth={false} />
    </>
  );
};

export default PublicRoute;
