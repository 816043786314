import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { adminRegister } from "../../actions/auth";

const AddUser = ({ adminRegister }) => {
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
  });
  const { email, phone } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const formatPhone = (e) => {
    let phone;
    const input = e.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if (input.length > 6) {
      phone = `(${zip}) ${middle}-${last}`;
    } else if (input.length > 3) {
      phone = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      phone = `(${zip}`;
    }
    setFormData({ ...formData, phone });
  };

  const [redirect, setRedirect] = useState(false);
  const onSubmit = async (e) => {
    e.preventDefault();
    adminRegister({ email, phone });
    setRedirect(true);
  };

  if (redirect) {
    return <Navigate to="/admin" />;
  }

  return (
    <div>
      <h1>Add New User</h1>
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div className="form-floating mb-3">
          <input
            type="email"
            placeholder="email@email.com"
            className="form-control"
            value={email}
            onChange={(e) => onChange(e)}
            name="email"
            style={{ backgroundColor: "#F8FCFF" }}
            required
          />
          <label htmlFor="email">User's Email</label>
        </div>

        <div className="form-floating mb-3">
          <input
            type="text"
            placeholder="555-555-5555"
            className="form-control"
            value={phone}
            onChange={(e) => formatPhone(e)}
            name="phone"
            style={{ backgroundColor: "#F8FCFF" }}
            required
          />
          <label htmlFor="name">User's Phone Number</label>
        </div>

        <div className="d-grid">
          <input
            type="submit"
            className="btn btn-primary btn-block mb-3 mt-2"
            value="Create Account"
          />
        </div>
      </form>
    </div>
  );
};

AddUser.propTypes = {
  adminRegister: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { adminRegister })(AddUser);
