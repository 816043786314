export const faq = [
  {
    id: "hiw0",
    question: "What Do I Get When I Sign Up",
    answer:
      "We provide you with a list of available phone numbers based on your area code. Once you choose a number, you can begin creating unlimited codes to attach to your marketing media; sign riders, social media, print flyers, and more. Finally, you will customize a message to be sent instantly when a lead uses your code. You will receive an email notification for each lead so you’ll never miss a potential client again. Update your messages any time, and transfer codes to new properties after they sell.",
    category: "How It Works",
  },
  {
    id: "hiw1",
    question: "Can Leads Auto-Import To My CRM",
    answer:
      "Yep. There are 2 ways. <ol><li>Sign up at Zapier.com, an integration system that sends leads directly to your CRM.</li><li>Send leads to your CRM through email. We follow the Real Estate Lead Metadata Specification, meaning, if you forward the email notification to your CRM it can automatically add it. Most CRMs are set up for this service including FiveStreet (Move, Inc.), TopProducer (Move, Inc.), BoomTown, Brivity, SmartTargeting (SmartZip Analytics, Inc.), Dakno Marketing, Follow Up Boss, GuerillaRealty.com, Opcity, RealOffice360, and Wise Agent. </li></ol>If  you have further questions about this service, please contact us.",
    category: "How It Works",
  },
  {
    id: "hiw2",
    question: "How Many People are Notified of a Lead",
    answer:
      "Our service allows your customizable notification emails to be sent to up to 5 separate email addresses. Integrating your CRM with Zapier.com will allow you to notify even more people through their service. ",
    category: "How It Works",
  },
  {
    id: "hiw3",
    question: "What Information Do I Get On A Lead",
    answer:
      "We collect the name, phone number, and code the lead used so you can track and follow up as needed.",
    category: "How It Works",
  },
  // {
  //   id: "p3",
  //   question: "How does the free Trial Work",
  //   answer:
  //     "Enter your email address, phone number and create a password. You will then be prompted to choose a local phone number that will be linked to your account, and then you will create your first lead code. You can begin using your code right away. Free trial lasts for 2 weeks. ",
  //   category: "Pricing",
  // },
  {
    id: "p0",
    question: "Is there a contract",
    answer:
      "Nope. Choose whether you prefer to be billed monthly, or save money with a yearly payment. Cancel at any time. ",
    category: "Pricing",
  },
  {
    id: "p2",
    question: "How do I pay",
    answer:
      "Simple, we set you up on auto pay to your credit or debit card. We will bill this card monthly (or yearly if you choose). You can update your card info in your settings or cancel anytime, no contract. ",
    category: "Pricing",
  },
  {
    id: "p1",
    question: "How can I change my plan",
    answer:
      "In your account settings you are able to upgrade or downgrade your plan based on the number of active codes you are using.",
    category: "Pricing",
  },
  {
    id: "oq0",
    question: "How Do I Print Signs",
    answer:
      "We’re awesome at software, but not at printing. Try your local print shop, or an online printing service like BuildASign.com.",
    category: "Other Questions",
  },
  {
    id: "oq1",
    question: "Where Can I Market My Codes",
    answer:
      "Use your codes anywhere and everywhere. On sign riders, social media posts, Craigslist or other sales sites, print ads and flyers. Anywhere you’d like to collect leads with a trackable call to action. Get Creative!",
    category: "Other Questions",
  },
];
