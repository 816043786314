import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";

import Spinner from "../layout/Spinner";

import { getUserById, adminCancelUser } from "../../actions/admin";
import { planIdToName, planCostById } from "../../utlis/plans";
import { prettyPhone, prettyDate } from "../../utlis/pretty";

const UserInfo = ({ getUserById, adminCancelUser, singleUser, loading }) => {
  const { id } = useParams();
  useEffect(() => {
    getUserById(id);
  }, [getUserById, id]);

  const [modal, setmodal] = useState({
    show: "none", //block
    theClass: "", //modal fade show
  });
  const { show, theClass } = modal;

  const [sendMessage, setSendMessage] = useState(true);

  //TO DO
  // create an action to get message count and last message timestamp, add it to stats object in admin redux
  // Create an action to get sign count

  return (
    <div>
      <h1 className="display-4">User Info</h1>
      <Link to="/admin">Back to Admin Dash</Link>
      {!loading ? (
        <Fragment>
          {singleUser ? (
            <Fragment>
              <ul>
                <li>Email: {singleUser.email}</li>
                <li>Forward Phone: {singleUser.forwardPhone}</li>
                <li>
                  Phone:
                  {singleUser.phone ? (
                    <>{prettyPhone(singleUser.phone)}</>
                  ) : (
                    <>Not Set</>
                  )}
                </li>
                <li>
                  {singleUser.plan && (
                    <>
                      Plan: {planIdToName(singleUser.plan)} -{" "}
                      <a
                        href={`https://dashboard.stripe.com/customers/${singleUser.stripeId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Open Stripe
                      </a>
                    </>
                  )}
                </li>
                <li>
                  {singleUser.plan && (
                    <>
                      Price: ${planCostById(singleUser.plan)}{" "}
                      {/* {planIdToName(singleUser.plan).includes("Yearly")
                  ? "Yearly"
                : "Monthly"} */}
                    </>
                  )}
                </li>
                <li>Sign Up Date: {prettyDate(singleUser.timestamp, "MST")}</li>
                {singleUser.cancelDate && (
                  <li>
                    Cancel Date: {prettyDate(singleUser.cancelDate, "MST")}
                  </li>
                )}
              </ul>
              <h4>Sign Stats</h4>
              <ul>
                <li>Number of Signs:</li>
              </ul>
              <h4>Message Stats</h4>
              <ul>
                <li>Number of Calls/Texts: </li>
                <li>Last Received: </li>
              </ul>
              {singleUser.plan && (
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setmodal({ show: "block", theClass: "show" });
                  }}
                >
                  Cancel Plan
                </button>
              )}
              <div
                className={"modal fade " + theClass}
                tabIndex="-1"
                role="dialog"
                style={{ display: show }}
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Cancel Your Plan</h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setmodal({ show: "none", theClass: "" });
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>
                        Are you sure you want to cancel your plan? Once
                        cancelled, we will release your phone number.
                      </p>

                      <button
                        className={
                          sendMessage
                            ? "btn btn-secondary m-3"
                            : "btn btn-outline-secondary m-3"
                        }
                        onClick={() => {
                          setSendMessage(!sendMessage);
                        }}
                      >
                        Send Message
                      </button>
                      <button
                        className={
                          !sendMessage
                            ? "btn btn-secondary m-3"
                            : "btn btn-outline-secondary m-3"
                        }
                        onClick={() => {
                          setSendMessage(!sendMessage);
                        }}
                      >
                        Don't Send Message
                      </button>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          adminCancelUser(singleUser._id, sendMessage);
                          setmodal({ show: "none", theClass: "" });
                        }}
                      >
                        Cancel Your Plan
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => {
                          setmodal({ show: "none", theClass: "" });
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <p>Error, user not found. Try reloading</p>
          )}
        </Fragment>
      ) : (
        <Spinner />
      )}

      {modal.show === "block" && <div className="modal-backdrop fade show" />}
    </div>
  );
};

UserInfo.propTypes = {
  getUserById: PropTypes.func.isRequired,
  adminCancelUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  singleUser: state.admin.singleUser,
  loading: state.admin.adminLoading,
});

export default connect(mapStateToProps, { getUserById, adminCancelUser })(
  UserInfo
);
