import React, { Fragment, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { register } from "../../actions/auth";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { assumptions } from "../../utlis/assumptions";

const Register = ({ setAlert, register, isAuthenticated }) => {
  const { search } = useLocation();
  const registerSourcePage = new URLSearchParams(search).get("s");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    phone: "",
  });
  const [showPassword, setShowPassword] = useState("password");

  const { name, email, password, phone } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const formatPhone = (e) => {
    let phone;
    const input = e.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if (input.length > 6) {
      phone = `(${zip}) ${middle}-${last}`;
    } else if (input.length > 3) {
      phone = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      phone = `(${zip}`;
    }
    setFormData({ ...formData, phone });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    ReactGA.event({
      category: "User",
      action: "Created an Account",
    });
    register({ name, email, password, phone, registerSourcePage });
  };

  if (isAuthenticated) {
    return <Navigate to={assumptions.afterRegister} />;
  }

  return (
    <Fragment>
      <div style={{ maxWidth: "330px", padding: "15px", margin: "0 auto" }}>
        <h1 className="large text-primary">Create Account</h1>
        <p className="lead">
          Ready to let Simple Sign Calls make your job easier? Let’s get you set
          up!
        </p>
        <form className="form" onSubmit={(e) => onSubmit(e)}>
          <div className="form-floating mb-3">
            <input
              type="email"
              placeholder="email@email.com"
              className="form-control"
              value={email}
              onChange={(e) => onChange(e)}
              name="email"
              style={{ backgroundColor: "#F8FCFF" }}
              required
            />
            <label htmlFor="email">Your Email</label>
          </div>

          <div className="form-floating mb-3">
            <input
              type="text"
              placeholder="555-555-5555"
              className="form-control"
              value={phone}
              onChange={(e) => formatPhone(e)}
              name="phone"
              style={{ backgroundColor: "#F8FCFF" }}
              required
            />
            <label htmlFor="name">Your Phone Number</label>
          </div>

          <div className="form-floating mb-3">
            <input
              type={showPassword ? "password" : "text"}
              placeholder={showPassword ? "Password" : "p@ssw0rd"}
              name="password"
              minLength="6"
              className="form-control"
              value={password}
              onChange={(e) => onChange(e)}
              style={{ backgroundColor: "#F8FCFF" }}
              required
            />
            <span
              style={{
                position: "relative",
                zIndex: 2,
                float: "right",
                marginRight: "5px",
                marginTop: "-30px",
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <FontAwesomeIcon icon={faEyeSlash} />
              )}
            </span>
            <label htmlFor="name">Password</label>
          </div>

          <div className="d-grid">
            <input
              type="submit"
              className="btn btn-primary btn-block mb-3 mt-2"
              value="Create Account"
            />
          </div>
        </form>
        <p className="my-1 mb-3">
          Already have an account? <Link to="/login">Sign In</Link>
        </p>
        <small className="mt-5 ">
          By registering you agree to our{" "}
          <Link to="/terms">Terms & Conditions</Link> and{" "}
          <Link to="/privacy">Privacy Policy</Link>.
        </small>
      </div>
    </Fragment>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, register })(Register);
