import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import { updateUser } from "../../actions/auth";
import { cancelPlan } from "../../actions/stripe";

import { sendReset } from "../../actions/auth";

const Settings = ({ auth: { loading, user }, updateUser, cancelPlan }) => {
  const [theUser, setTheUser] = useState({
    email: "",
    forwardPhone: "",
    timeZone: "",
    brokerage: "",
    notification: "",
    plan: "",
    phone: "",
    callScript: "",
  });

  const formatPhone = (e) => {
    let phone;
    const input = e.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if (input.length > 6) {
      phone = `(${zip}) ${middle}-${last}`;
    } else if (input.length > 3) {
      phone = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      phone = `(${zip}`;
    }
    setTheUser({ ...theUser, forwardPhone: phone });
  };

  const [modal, setmodal] = useState({
    show: "none", //block
    theClass: "", //modal fade show
  });

  const [sampleMessage, setSampleMessage] = useState("");

  const { show, theClass } = modal;

  useEffect(() => {
    if (user) {
      setTheUser({
        email: user.email,
        forwardPhone: user.forwardPhone,
        timeZone: user.timeZone,
        brokerage: user.brokerage,
        notification: user.notification,
        plan: user.plan,
        phone: user.phone,
        callScript: user.callScript,
      });
      setSampleMessage(updateSampleMessage(user.notification));
    } else {
      setTheUser({
        email: "",
        forwardPhone: "",
        timeZone: "",
        brokerage: "",
        notification: "",
        plan: "",
        callScript: "",
      });
    }
  }, [user]);

  const {
    email,
    forwardPhone,
    timeZone,
    brokerage,
    notification,
    plan,
    phone,
    callScript,
  } = theUser;

  const onChange = (e) => {
    setTheUser({ ...theUser, [e.target.name]: e.target.value });
    setSampleMessage(updateSampleMessage(notification));
  };

  const updateSampleMessage = (msg) => {
    if (msg) {
      let message = msg;
      message = message.replace(/{{caller}}/gi, "John Doe");
      message = message.replace(/{{phone}}/gi, "555-555-555");
      message = message.replace(/{{code}}/gi, "12345");
      message = message.replace(/{{tracking}}/gi, "123 Main St");
      message = message.replace(/{{type}}/gi, "text");
      return message;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    updateUser({
      email,
      forwardPhone,
      timeZone,
      brokerage,
      notification,
      callScript,
    });
  };

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <h1 className="display-4">Settings</h1>
          <div className="row">
            <div className="container">
              <form className="form" onSubmit={(e) => onSubmit(e)}>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => onChange(e)}
                    style={{ backgroundColor: "#F8FCFF" }}
                    required
                  />
                  <label htmlFor="email">Email Address</label>
                </div>
                <div className="form-floating mb-3">
                  <select
                    className="form-control"
                    id="timeZone"
                    name="timeZone"
                    value={timeZone}
                    onChange={(e) => onChange(e)}
                    style={{ backgroundColor: "#F8FCFF" }}
                  >
                    <option />
                    <option value="America/Los_Angeles">Pacific</option>
                    <option value="America/Denver">Mountain</option>
                    <option value="America/Phoenix">Mountain (AZ)</option>
                    <option value="America/Chicago">Central</option>
                    <option value="America/New_York">Eastern</option>
                  </select>
                  <label htmlFor="timeZone">Time Zone</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="forwardPhone"
                    name="forwardPhone"
                    value={forwardPhone}
                    onChange={(e) => formatPhone(e)}
                    style={{ backgroundColor: "#F8FCFF" }}
                    required
                  />
                  <label htmlFor="forwardPhone">Your Phone Number</label>
                </div>
                {/* <div className="form-group">
                    <label htmlFor="text">Your Brokerage</label>
                    <input
                      type="text"
                      className="form-control"
                      id="brokerage"
                      name="brokerage"
                      value={brokerage}
                      onChange={e => onChange(e)}
                      required
                    />
                  </div> */}
                <div className="form-floating mb-5">
                  <textarea
                    className="form-control"
                    id="callScript"
                    name="callScript"
                    style={{ height: "150px", backgroundColor: "#F8FCFF" }}
                    onChange={(e) => onChange(e)}
                    value={callScript}
                    required
                  />
                  <label htmlFor="notification">Your Call In Script:</label>
                  <small>
                    This is the message callers will hear when they call instead
                    of text.
                  </small>
                </div>

                <div className="form-floating mb-3">
                  <textarea
                    className="form-control"
                    id="notification"
                    name="notification"
                    style={{ height: "150px", backgroundColor: "#F8FCFF" }}
                    onChange={(e) => onChange(e)}
                    value={notification}
                    required
                  />
                  <label htmlFor="notification">Your Email Nofification:</label>
                  <p>
                    <b>Sample Email Notification:</b>
                    <br />
                    {sampleMessage}
                  </p>
                  <small>
                    <ul>
                      <li>{"{{caller}}"}</li>
                      <li>{"{{phone}}"}</li>
                      <li>{"{{code}}"}</li>
                      <li>{"{{tracking}}"}</li>
                      <li>{"{{type}}"}</li>
                    </ul>
                  </small>
                </div>

                <div className="d-grid">
                  <button type="submit" className="btn btn-primary">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>

          <hr />
          <h3>Reset Password</h3>
          <p>Request a link to reset your password.</p>
          <button className="btn btn-primary" onClick={sendReset({ email })}>
            Reset
          </button>
          <hr />
          <h3>Your Plan</h3>
          {plan ? (
            <Fragment>
              <p>
                <Link to="/update-plan">Plan Info or Change Your Plan</Link>
              </p>
              <p>
                <Link to="/update-card">Update Your Card</Link>
              </p>
              <span
                className="clickable"
                onClick={() => {
                  setmodal({ show: "block", theClass: "show" });
                }}
              >
                Cancel Your Plan
              </span>
            </Fragment>
          ) : (
            <Fragment>
              {phone ? (
                <Fragment>
                  Free Trial - <Link to="/select-plan">Select A Plan</Link>
                </Fragment>
              ) : (
                <Fragment>
                  <Link to="/look-up">Get Started</Link>
                </Fragment>
              )}
            </Fragment>
          )}

          <div
            className={"modal fade " + theClass}
            tabIndex="-1"
            role="dialog"
            style={{ display: show }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Cancel Your Plan</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setmodal({ show: "none", theClass: "" });
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    Are you sure you want to cancel your plan? Once cancelled,
                    we will release your phone number.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      cancelPlan();
                      setmodal({ show: "none", theClass: "" });
                    }}
                  >
                    Cancel Your Plan
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setmodal({ show: "none", theClass: "" });
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
      {modal.show === "block" && <div className="modal-backdrop fade show" />}
    </Fragment>
  );
};

Settings.propTypes = {
  updateUser: PropTypes.func.isRequired,
  cancelPlan: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { updateUser, cancelPlan })(Settings);
