import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";
// import { getMessageById } from "../../actions/messages";
// import { getSignByCode } from "../../actions/sign";

import { findMessage, findSignByCode } from "../../utlis/helpers";

const MessageDetail = ({ signs, messages }) => {
  const { id } = useParams();
  const [showLink, setShowLink] = useState(false);

  const [message, setMessage] = useState(null);
  useEffect(() => {
    setMessage(findMessage(messages, id));
  }, [messages, id]);

  const [sign, setSign] = useState(null);
  useEffect(() => {
    if (message) {
      setSign(findSignByCode(signs, message.code));
    }
  }, [message, signs]);

  useEffect(() => {
    if (sign) {
      setShowLink(true);
    }
  }, [sign]);

  return (
    <div>
      {!message ? (
        <div className="position-absolute top-50 start-50 translate-middle">
          <h3>Could Not Find Message</h3>
          <Link to="/messages">Back To Messages</Link>
        </div>
      ) : (
        <Fragment>
          <Link to="/messages">Back to Messages</Link>
          <h1 className="display-4">
            {message.type.toUpperCase()} to{" "}
            {showLink && message.code !== "*" ? (
              <Fragment>
                {sign && (
                  <>
                    <Link to={`/signs/${sign._id}`}>{sign.code}</Link>
                  </>
                )}
              </Fragment>
            ) : (
              <Fragment> {message.code}</Fragment>
            )}
          </h1>
          <div className="card" style={{ width: "25rem" }}>
            <div className="card-body">
              {message.caller && (
                <h5 className="card-title text-center">{message.caller}</h5>
              )}
              <h5 className="card-title text-center">
                {message.from &&
                  message.from
                    .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "($2) $3-$4")
                    .replace("+", "")}
              </h5>
              <h6 className="card-subtitle mb-2 text-muted text-center">
                <Moment format="D MMM YY H:mm A">{message.timestamp}</Moment>
              </h6>
              <div className="card-text">
                <div className="alert alert-dark" role="alert">
                  {message.type === "text" ? message.message : message.code}
                </div>
              </div>
              {message.type === "text" ? (
                <div className="card-text text-right">
                  <div className="alert alert-primary text-right" role="alert">
                    {message.textSent}
                  </div>
                </div>
              ) : (
                <div className="card-text text-right">
                  <div className="alert alert-primary text-right" role="alert">
                    Forwarded call to {message.forward}
                  </div>
                </div>
              )}
            </div>
            <ul className="list-group list-group-flush">
              {message.address ? (
                <Fragment>
                  <li className="list-group-item">{message.address}</li>
                </Fragment>
              ) : (
                <li className="list-group-item">No tracking was set</li>
              )}
              <li className="list-group-item">
                {message.notify ? (
                  <Fragment>
                    Email notification sent to {message.notify.join(", ")}
                  </Fragment>
                ) : (
                  <Fragment>No notification sent.</Fragment>
                )}
              </li>
            </ul>
          </div>
        </Fragment>
      )}
    </div>
  );
};

MessageDetail.propTypes = {
  messages: PropTypes.array.isRequired,
  signs: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  messages: state.message.messages,
  signs: state.sign.signs,
});

export default connect(mapStateToProps, {})(MessageDetail);
