import {
  GET_MESSAGES,
  CLEAR_MESSAGES,
  MESSAGES_ERROR,
  LOADING_MESSAGES,
} from "../actions/types";

const initialState = {
  messages: [],
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING_MESSAGES:
      return { ...state, loading: true };
    case GET_MESSAGES:
      return { ...state, messages: payload, loading: false };
    case CLEAR_MESSAGES:
      return { ...state, messages: [], loading: false };
    case MESSAGES_ERROR:
    default:
      return state;
  }
}
