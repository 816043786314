import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const Faq = ({ faq }) => {
  const { question, answer } = faq;
  const [showHide, setShowHide] = useState("collapse none");
  const onClick = () => {
    showHide === "collapse none"
      ? setShowHide("collapse show")
      : setShowHide("collapse none");
  };
  return (
    <Fragment>
      <div className="card">
        <div className="card-header">
          <div
            className="d-flex justify-content-between align-items-center"
            onClick={onClick}
            style={{ cursor: "pointer" }}
          >
            <button className="btn">{question}</button>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
        <div className={showHide}>
          <div
            className="card-body"
            dangerouslySetInnerHTML={{ __html: answer }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Faq;
