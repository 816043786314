import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import Message from "../messages/Message";
import SearchBar from "../search/SearchBar";
import Card from "./Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faPhoneVolume,
  faSign,
} from "@fortawesome/free-solid-svg-icons";

import { messageCount, signCount } from "../../utlis/helpers";
import { prettyPhone } from "../../utlis/pretty";

// import UpdateCheck from "../stripe/UpdateCheck";

const Dashboard = ({ auth: { loading, user }, messages, signs }) => {
  const [theUser, setTheUser] = useState({
    phone: "",
    onboard: 0,
  });

  const [stats, setStats] = useState({
    texts: 0,
    calls: 0,
    signs: 0,
  });

  useEffect(() => {
    const texts = messageCount(messages, "text");
    const calls = messageCount(messages, "call");
    const signLength = signCount(signs);
    setStats({
      texts,
      calls,
      signs: signLength,
    });
  }, [messages, signs]);

  useEffect(() => {
    setTheUser(
      user === null
        ? {}
        : {
            phone: user.phone,
            onboard: user.onboard,
          }
    );
  }, [user]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {/* <UpdateCheck /> */}
          <div
            className="row d-flex justify-content-center"
            style={{ padding: "1em" }}
          >
            <div className="d-flex flex-column">
              {theUser.phone ? (
                <>
                  <h1 className="display-4">{prettyPhone(theUser.phone)}</h1>
                </>
              ) : (
                <>
                  {!theUser ? (
                    <h1 className="display-4">{"  "}</h1>
                  ) : (
                    <div className="d-grid gap-2">
                      <Link to="/look-up">
                        <button className="btn btn-lg btn-danger">
                          Set Up Phone Number
                        </button>
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col">
              <Card
                name="Texts"
                icon={<FontAwesomeIcon icon={faComment} size="5x" />}
                link="/messages/type/text"
                count={stats.texts}
                color="#56699e"
              />
            </div>
            <div className="col">
              <Card
                name="Calls"
                link="/messages/type/call"
                icon={<FontAwesomeIcon icon={faPhoneVolume} size="5x" />}
                count={stats.calls}
                color="#436ba5"
              />
            </div>
            <div className="col">
              <Card
                name="Codes"
                link="/signs"
                icon={<FontAwesomeIcon icon={faSign} size="5x" />}
                count={stats.signs}
                color="#00757d"
              />
            </div>
          </div>

          <SearchBar />
          <hr />
          <h4 className="display-4 mt-5">Recent Leads</h4>
          <div className="row">
            <div className="w-100">
              <div className="list-group">
                <span className="list-group-item list-group-item-dark disabled">
                  <div className="row">
                    <div className="col-4">
                      <b>Code</b>
                    </div>
                    <div className="col-4">
                      <b>Tracking</b>
                    </div>
                    <div className="col-4">
                      <b>When</b>
                    </div>
                  </div>
                </span>
                {messages.length > 0 ? (
                  messages
                    .slice(0, 10)
                    .map((message) => (
                      <Message key={message._id} message={message} />
                    ))
                ) : (
                  <h3 className="text-center m-4">No Messages Yet.</h3>
                )}
                <>
                  {messages.length > 10 ? (
                    <Link to="/messages">
                      <button className="btn btn-secondary btn-block">
                        Show More
                      </button>
                    </Link>
                  ) : (
                    ""
                  )}
                </>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

// Dashboard.propTypes = {};

const mapStateToProps = (state) => ({
  messages: state.message.messages,
  signs: state.sign.signs,
  auth: state.auth,
});

export default connect(mapStateToProps)(Dashboard);
