import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { getPhones, setPhone } from "../../actions/lookup";
import { setAlert } from "../../actions/alert";
import { prettyPhone } from "../../utlis/pretty";

import UpdateCheck from "../stripe/UpdateCheck";
import { assumptions } from "../../utlis/assumptions";

const LookUp = ({
  phone,
  getPhones,
  setPhone,
  setAlert,
  lookup: { numbers, loading },
  auth: { user },
}) => {
  const [formData, setFormData] = useState({
    area: "",
  });

  const [dev, setDev] = useState(false);
  useEffect(() => {
    if (window.location.href.includes("simplesigncalls")) {
      setDev(false);
    } else {
      setDev(true);
    }
  }, []);

  const [theUser, setTheUser] = useState({
    plan: null,
    loading: true,
    phone: null,
  });

  useEffect(() => {
    setTheUser(
      user === null
        ? {}
        : {
            plan: user.plan,
            loading: false,
            phone: user.phone,
          }
    );
  }, [user]);

  const { area } = formData;

  const onChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (area.length !== 3) {
      setAlert("Search a 3 digit area code", "danger");
    } else {
      getPhones(area);
    }
  };

  if (theUser.phone) {
    setAlert(
      "You got it! Now, let's create a code to start marketing",
      "success"
    );
  }

  if (user) {
    if (user.phone) {
      return <Navigate to={assumptions.afterPhone} />;
    }
  }
  // if (!theUser.loading && !theUser.plan) {
  //   return <Redirect to="/select-plan" />;
  // }

  return (
    <>
      <UpdateCheck />
      <h1 className="display-4">Set Up Your Phone Number</h1>

      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <input
            type="number"
            placeholder="Search by 3 Digit Area Code"
            className="form-control"
            style={{ backgroundColor: "#F8FCFF" }}
            defaultValue={area}
            onInput={(e) => onChange(e)}
            name="area"
            minLength="3"
            maxLength="3"
            required
          />
        </div>
        <div className="d-grid gap-2">
          <button className="btn btn-primary my-3">Search Phone Numbers</button>
        </div>
        {/* <input
          type="submit"
          className="btn btn-primary btn-block mb-3 mt-2"
          value="Search Phone Numbers"
        /> */}
      </form>
      <hr />
      {!loading && numbers.length === 0 ? (
        <h2>No codes in that area code now, try another area code</h2>
      ) : (
        <Fragment />
      )}
      {!loading ? (
        <div className="list-group">
          {/* set fake number for dev */}
          {dev && (
            <>
              {}
              <div
                to={`/lookup/assign/+1${area}5555555`}
                className="list-group-item list-group-item-action"
              >
                <div className="row">
                  <div className="col">{prettyPhone(`+1${area}5555555`)}</div>
                  <div className="col">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setPhone(`+1${area}5555555`);
                        setTheUser({ ...theUser, phone: `+1${area}5555555` });
                        ReactGA.event({
                          category: "Other",
                          action: "Setup Phone",
                        });
                      }}
                    >
                      Choose Fake For Testing
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          {numbers.map((number) => (
            <div
              key={number.phoneNumber}
              to={`/lookup/assign/${number.phoneNumber}`}
              className="list-group-item list-group-item-action"
            >
              <div className="row">
                <div className="col">{number.friendlyName}</div>
                <div className="col">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setPhone(`${number.phoneNumber}`);
                      setTheUser({ ...theUser, phone: number.phoneNumber });
                      ReactGA.event({
                        category: "Other",
                        action: "Setup Phone",
                      });
                    }}
                  >
                    Choose
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        "Search to see available numbers"
      )}
    </>
  );
};

LookUp.propTypes = {
  // user: PropTypes.object.isRequired,
  getPhones: PropTypes.func.isRequired,
  setPhone: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  lookup: state.lookup,
  auth: state.auth,
});

export default connect(mapStateToProps, { getPhones, setPhone, setAlert })(
  LookUp
);
