import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Spinner from "../layout/Spinner";

import { findSign } from "../../utlis/helpers";
import { prettyPhone } from "../../utlis/pretty";

const TestSign = ({ signs, user }) => {
  const { id } = useParams();

  const [sign, setSign] = useState(null);
  useEffect(() => {
    setSign(findSign(signs, id));
  }, [signs, id]);

  return (
    <>
      {sign ? (
        <div
          className="modal modal-fullscreen fade show"
          tabIndex="-1"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="container">
                <div className="modal-header">
                  <h1 className="modal-title display-4">
                    Test Out Code {sign.code}
                  </h1>
                  <Link to={`/signs/${id}`}>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </Link>
                </div>
                <div className="modal-body">
                  <div className="d-grid gap-3">
                    {user ? (
                      <h1 className="display-6">
                        1 -{" "}
                        <a href={`sms:${user.phone}&body=${sign.code}`}>
                          Text {sign.code} to {prettyPhone(user.phone)}
                        </a>
                      </h1>
                    ) : (
                      <h1 className="display-6">
                        1 - Text {sign.code} to your sign call phone
                      </h1>
                    )}
                    <h1 className="display-6">2 - Wait for the response</h1>
                    <p>
                      You should get a text back within a few seconds that says:
                      "{sign.message}"
                    </p>
                    <h1 className="display-6">3 - Get Notified</h1>
                    {sign.notify ? (
                      <p>
                        Moments after you received a text response,{" "}
                        {sign.notify} should receive an email notification
                        saying you text on the code {sign.code}
                      </p>
                    ) : (
                      <p>
                        You didn't set up an email notification, but you can add
                        an email to receive an email notifcation every time the
                        somone texts {sign.code}.{" "}
                        <Link to={`/signs/${id}`}>
                          Add a notification address
                        </Link>
                      </p>
                    )}
                    <h1 className="display-6">4 - Start Marketing It!</h1>
                    <p>
                      Put "Text {sign.code} to {prettyPhone(user.phone)}" on
                      your marketing. Definitely add it to your for sale signs,
                      but don't miss out on other lead capturing ideas like
                      putting it on flyers, using it at open houses, or even on
                      facebook. It's a great call to action to get more leads.
                    </p>
                    <div className="d-grid gap-2">
                      <Link to={`/signs/${id}`}>
                        <button
                          className="btn btn-lg btn-primary"
                          type="button"
                        >
                          It Worked!
                        </button>
                      </Link>
                    </div>
                    <div>
                      I had some issues... <Link to="/feedback">Help me.</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Spinner />
        </>
      )}
      {/* <Spinner /> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  signs: state.sign.signs,
  user: state.auth.user,
});

export default connect(mapStateToProps)(TestSign);
