import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

const Card = ({ icon, name, link, count, color }) => {
  const id = name.toLowerCase();

  const [backgroundColor, setBackgroundColor] = useState(color);

  const [forward, setForward] = useState(false);
  if (forward) {
    return <Navigate to={link} />;
  }
  return (
    <div
      className={`card text-white`}
      style={{
        cursor: "pointer",
        backgroundColor,
        textDecoration: "none",
      }}
      id={id}
      onClick={() => setForward(!forward)}
      onMouseEnter={() => setBackgroundColor("#9e3d07")}
      onMouseLeave={() => setBackgroundColor(color)}
    >
      <div className="card-header text-center">
        {/* <FontAwesomeIcon icon={icon} size="5x" /> */}
        {icon}
      </div>
      <div className="card-body">
        <h1 className="display-4 text-center">{count}</h1>
        <p className="text-center">
          {name}
          <br />
        </p>
      </div>
    </div>
  );
};

Card.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  // count: PropTypes.number.isRequired,
};

export default Card;
