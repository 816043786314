import React, { Fragment, useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { sendSearch } from "../../actions/search";

const SearchBar = ({ sendSearch, search: { search, loading } }) => {
  const [dropdown, setDropdown] = useState("none");
  const [searchDropdown, setSearchDropdown] = useState("none");
  const [searchData, setSearchData] = useState({
    query: "",
    field: "Sign Code",
    redirect: false,
  });
  const { query, field } = searchData;

  const [searchResults, setSearchResults] = useState({
    results: [],
    loading: true,
  });

  useEffect(() => {
    !loading &&
      setSearchResults({
        results: search,
        loading: false,
      });
  }, [search, loading]);

  const handleClick = () => {
    dropdown === "none" ? setDropdown("block") : setDropdown("none");
  };

  const chooseField = (event) => {
    handleClick();
    setSearchData({ ...searchData, field: event.target.innerText });
  };

  const onChange = async (e) => {
    setSearchDropdown("block");
    const value = e.target.value;
    setSearchData({ ...searchData, [e.target.name]: value });
    await sendSearch({
      query: value,
      field: searchData.field,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setSearchData({ query, field, redirect: true });
    const link = "/search/" + query;
    return <Navigate to={link} />;
  };

  const codeSearch = (
    <Fragment>
      <div className="dropdown" style={{ display: `${searchDropdown}` }}>
        {!searchResults.results ? (
          <span className="dropdown-item">No results found</span>
        ) : searchResults.results.length > 0 ? (
          searchResults.results.map((r) => (
            <Link to={`/signs/${r._id}`} key={r._id} className="dropdown-item">
              {r.code}
            </Link>
          ))
        ) : (
          <span className="dropdown-item">No results found</span>
        )}
      </div>
    </Fragment>
  );
  const phoneSearch = (
    <Fragment>
      <div className="dropdown" style={{ display: `${searchDropdown}` }}>
        {!searchResults.results ? (
          <span className="dropdown-item">No results found</span>
        ) : searchResults.results.length > 0 ? (
          searchResults.results.map((r) => (
            <Link
              to={`/messages/${r._id}`}
              key={r._id}
              className="dropdown-item"
            >
              {r.from}
            </Link>
          ))
        ) : (
          <span className="dropdown-item">No results found</span>
        )}
      </div>
    </Fragment>
  );
  const addressCodeSearch = (
    <Fragment>
      <div className="dropdown" style={{ display: `${searchDropdown}` }}>
        {!searchResults.results ? (
          <span className="dropdown-item">No results found</span>
        ) : searchResults.results.length > 0 ? (
          searchResults.results.map((r) => (
            <Link to={`/signs/${r._id}`} key={r._id} className="dropdown-item">
              {r.address}
            </Link>
          ))
        ) : (
          <span className="dropdown-item">No results found</span>
        )}
      </div>
    </Fragment>
  );
  const addressMessageSearch = (
    <Fragment>
      <div className="dropdown" style={{ display: `${searchDropdown}` }}>
        {!searchResults.results ? (
          <span className="dropdown-item">No results found</span>
        ) : searchResults.results.length > 0 ? (
          searchResults.results.map((r) => (
            <Link
              to={`/messages/${r._id}`}
              key={r._id}
              className="dropdown-item"
            >
              {r.address}
            </Link>
          ))
        ) : (
          <span className="dropdown-item">No results found</span>
        )}
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      <div className="mb-5 mt-5">
        <form className="form" onSubmit={(e) => onSubmit(e)}>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <button
                className="btn btn-outline-secondary dropdown-toggle"
                onClick={() => handleClick()}
              >
                {searchData.field}
              </button>
              <div className="dropdown-menu" style={{ display: `${dropdown}` }}>
                <span
                  className="dropdown-item"
                  onClick={(ev) => chooseField(ev)}
                  style={{ cursor: "pointer" }}
                >
                  Sign Code
                </span>
                <span
                  className="dropdown-item"
                  onClick={(ev) => chooseField(ev)}
                  style={{ cursor: "pointer" }}
                >
                  Phone Number
                </span>
                <span
                  className="dropdown-item"
                  onClick={(ev) => chooseField(ev)}
                  style={{ cursor: "pointer" }}
                >
                  Sign Address
                </span>
                <span
                  className="dropdown-item"
                  onClick={(ev) => chooseField(ev)}
                  style={{ cursor: "pointer" }}
                >
                  Message Address
                </span>
              </div>
            </div>
            <input
              type="text"
              placeholder={`Search ${searchData.field}`}
              className="form-control"
              value={query}
              onChange={(e) => onChange(e)}
              name="query"
              required
            />
          </div>
        </form>
        {searchData.field === "Sign Code" && codeSearch}
        {searchData.field === "Phone Number" && phoneSearch}
        {searchData.field === "Sign Address" && addressCodeSearch}
        {searchData.field === "Message Address" && addressMessageSearch}
      </div>
    </Fragment>
  );
};

SearchBar.propTypes = {
  sendSearch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  search: state.search,
});

export default connect(mapStateToProps, { sendSearch })(SearchBar);
