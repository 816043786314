import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updatePlan } from "../../actions/stripe";
import Spinner from "../layout/Spinner";

import { livePlans, devPlans, grandfatherPlans } from "../../utlis/plans";
import { signCount as getSignCount } from "../../utlis/helpers";

const UpdatePlan = ({ auth: { user }, signs, updatePlan }) => {
  const [signCount, setSignCount] = useState(0);
  useEffect(() => {
    setSignCount(getSignCount(signs));
  }, [signs]);

  const [plans, setPlans] = useState([]);
  useEffect(() => {
    if (window.location.href.includes("simplesigncalls")) {
      if (user) {
        if (user.planList === "grandfather") {
          setPlans(grandfatherPlans);
        } else {
          setPlans(livePlans);
        }
      } else {
        setPlans(livePlans);
      }
    } else {
      setPlans(devPlans);
    }
  }, [user]);

  const [plan, setPlan] = useState(null);
  const [noPlan, setNoPlan] = useState(false);
  useEffect(() => {
    if (user && plans.length > 0) {
      const index = plans.findIndex((plan) => plan.plan === user.plan);
      if (index > -1) {
        setPlan(plans[index]);
      } else {
        setNoPlan(true);
      }
    }
  }, [user, plans]);

  const [upPlan, setUpPlan] = useState(null);
  const [downPlan, setDownPlan] = useState(null);

  useEffect(() => {
    if (user) {
      if (plan) {
        if (plan.minCodes > 0) {
          setDownPlan(plans[plan.id - 1]);
        }

        if (parseInt(plan.codes) < parseInt(plans[plan.id + 1].codes)) {
          setUpPlan(plans[plan.id + 1]);
        }
      }
    }
  }, [user, plan, plans]);

  const [lower, setLower] = useState(false);
  useEffect(() => {
    if (plan) {
      if (plan.minCodes > signCount && downPlan) {
        setLower(true);
      }
    }
  }, [signCount, plan, downPlan]);

  const [updated, setUpdated] = useState(false);
  const onSubmit = (e, plan) => {
    e.preventDefault();
    updatePlan(plan);
    setUpdated(true);
  };

  if (updated && plans.length > 0) {
    return <Navigate to="/dashboard" />;
  }
  if (noPlan && plans.length > 0) {
    return <Navigate to="/select-plan" />;
  }

  return (
    <>
      {plan ? (
        <>
          <h1 className="display-4 mb-3`">Update Plan</h1>
          <div className="row row-cols-1 row-cols-md-3 g-4 mt-3">
            {downPlan !== null && (
              <div className="col">
                <div
                  className="card border-dark h-100 shadow"
                  style={{ maxWidth: "18rem" }}
                >
                  <div className="card-header">Lower Your Plan</div>
                  <div className="card-body text-dark">
                    {lower ? (
                      <>
                        <h5 className="card-title">You can lower your plan</h5>
                        <p className="card-text">
                          You have less codes than the lower plan. You should
                          lower your plan.
                        </p>
                        <form onSubmit={(e) => onSubmit(e, downPlan.plan)}>
                          <button
                            type="submit"
                            className="btn btn-primary text-center"
                          >
                            Select Plan
                          </button>
                        </form>
                      </>
                    ) : (
                      <>
                        <p className="card-text">
                          If you lower the number of codes you use, you could
                          lower your plan. The max number of codes you could
                          have on the lower plan is {downPlan.codes}.
                        </p>
                        <button
                          className="btn btn-secondary text-center"
                          disabled
                        >
                          Select Plan
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="col">
              <div
                className="card border-dark h-100 shadow"
                style={{ maxWidth: "18rem" }}
              >
                <div className="card-header">Your Current Plan</div>
                <div className="card-body">
                  <h5 className="card-title">{plan.codes} Codes Plan</h5>
                  <p className="card-text">
                    You currently have {signCount} codes.
                  </p>
                </div>
              </div>
            </div>

            <div className="col">
              <div
                className="card border-dark h-100 shadow"
                style={{ maxWidth: "18rem" }}
              >
                <div className="card-header">Upgrade</div>
                <div className="card-body text-dark">
                  <h5 className="card-title">Need more codes?</h5>
                  {upPlan ? (
                    <>
                      <p className="card-text">Upgrade your plan.</p>
                      <p className="card-text">
                        Get {upPlan.codes} codes for ${upPlan.price}
                      </p>
                      <form onSubmit={(e) => onSubmit(e, upPlan.plan)}>
                        <button
                          type="submit"
                          className="btn btn-primary text-center"
                        >
                          Select Plan
                        </button>
                      </form>
                    </>
                  ) : (
                    <>
                      <p className="card-text">
                        It looks like you are at our highest standard plan. Not
                        to worry, we can create a plan that will work for you.
                        Let's talk.
                      </p>
                      <p className="text-center">
                        <Link to="/feedback">
                          <button className="btn btn-secondary">
                            Contact Us
                          </button>
                        </Link>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

UpdatePlan.propTypes = {
  updatePlan: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  signs: state.sign.signs,
});

export default connect(mapStateToProps, { updatePlan })(UpdatePlan);
