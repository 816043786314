import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectStripe, CardElement } from "react-stripe-elements";
import { updateCard } from "../../actions/stripe";

const UpdateCard = ({ updateCard, stripe, auth: { user } }) => {
  const [checkout, setCheckout] = useState({ paid: false });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (stripe && user) {
      const { token, error } = await stripe.createToken();
      if (!error) {
        updateCard(token.id, user.email);
        setCheckout({ paid: true });
      } else {
        console.log(error);
      }
    } else {
      console.log("stripe or user did not load.");
    }
  };

  const createOptions = () => {
    return {
      style: {
        base: {
          fontSize: "18px",
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#050608",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    };
  };
  return (
    <Fragment>
      <h1 className="display-4">Update Payment Info</h1>
      <form onSubmit={(e) => onSubmit(e)}>
        <CardElement {...createOptions()} onReady={(el) => el.focus()} />
        {checkout.paid ? (
          <button
            className="btn btn-primary btn-lg btn-block"
            style={{ marginTop: "40px" }}
            disabled
          >
            Update
          </button>
        ) : (
          <button
            className="btn btn-primary btn-lg btn-block"
            style={{ marginTop: "40px" }}
          >
            Update
          </button>
        )}
      </form>
    </Fragment>
  );
};

UpdateCard.propTypes = {
  updateCard: PropTypes.func.isRequired,
  stripe: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { updateCard })(
  injectStripe(UpdateCard)
);
