import axios from "axios";
import {
  GET_SIGNS,
  GET_SIGN,
  SIGN_ERROR,
  UPDATE_SIGN,
  UPDATE_SIGN_ERROR,
  CREATE_SIGN,
  CLEAR_SIGNS,
  CLEAR_SINGLE_SIGN,
  DELETE_SIGN,
  CHANGE_PLAN_NEEDED,
  SET_PLAN,
  UPDATE_TEMP,
} from "../actions/types";
import { setAlert } from "./alert";
import { plans } from "../utlis/plans";

//Get all signs by user
export const getSigns = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/signs");

    dispatch({
      type: GET_SIGNS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SIGN_ERROR,
      payload: error,
    });
  }
};

//Get Sign By ID
export const getSignById = (signId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/signs/${signId}`);

    dispatch({
      type: GET_SIGN,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SIGN_ERROR,
      payload: error,
    });
  }
};

//Get Sign By Code
export const getSignByCode = (code) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/signs/code/${code}`);

    dispatch({
      type: GET_SIGN,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SIGN_ERROR,
      payload: error,
    });
  }
};

//Update Sign
export const updateSign =
  ({
    id,
    phone,
    message,
    notify,
    notify2,
    notify3,
    notify4,
    notify5,
    forward,
    address,
    unit,
    state,
    zip,
  }) =>
  async (dispatch) => {
    // scroll To Top
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      phone,
      message,
      notify,
      notify2,
      notify3,
      notify4,
      notify5,
      forward,
      address,
      unit,
      state,
      zip,
    });

    try {
      const res = await axios.post(`/api/signs/${id}`, body, config);

      dispatch({
        type: UPDATE_SIGN,
        payload: res.data,
      });

      dispatch(setAlert("Code Updated!", "success", 10000));
      // dispatch(getSignById(id));
      dispatch(getSigns());
    } catch (err) {
      console.log(err);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: UPDATE_SIGN_ERROR,
      });
    }
  };

export const createTempSign =
  (code, message, notify, forward, address, temp) => async (dispatch) => {
    const sign = {
      code,
      message,
      notify,
      forward,
      address,
    };
    const phone = temp;
    dispatch({
      type: UPDATE_TEMP,
      payload: { sign, phone },
    });
  };

export const createSign =
  (code, phone, message, notify, forward, address, unit, state, zip) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(
      code,
      phone,
      message,
      notify,
      forward,
      address,
      unit,
      state,
      zip
    );

    try {
      const res = await axios.post("/api/signs", body, config);
      dispatch({
        type: CREATE_SIGN,
        payload: res.data,
      });

      // dispatch({
      //   type: GET_SIGN,
      //   payload: res.data,
      // });
      dispatch(setAlert("Code created!", "success"));
      dispatch(getSigns());
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (err) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      const errors = err.response.data.errors;
      console.log(err);

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: UPDATE_SIGN_ERROR,
      });
    }
  };

export const clearSigns = () => (dispatch) => {
  dispatch({
    type: CLEAR_SIGNS,
  });
};

export const clearSingleSign = () => (dispatch) => {
  dispatch({
    type: CLEAR_SINGLE_SIGN,
  });
};

export const deleteSign = (id) => async (dispatch) => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify();

  try {
    const link = "/api/signs/delete/" + id;
    const res = await axios.post(link, body, config);

    dispatch({
      type: DELETE_SIGN,
      playload: res.data,
    });
    dispatch(getSigns());
  } catch (err) {
    const errors = err.response.data.errors;
    console.log(err);

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: UPDATE_SIGN_ERROR,
    });
  }
};

export const signPlan = () => async (dispatch) => {
  try {
    const sign = await axios.get("/api/signs/");
    const signCount = sign.data.length; //this is the count before the sign is made.

    const user = await axios.get("/api/auth");

    const userPlan = user.data.user.plan;

    let goodToGo;
    if (
      (signCount < 100 && userPlan === plans[3].plan) ||
      (signCount < 100 && userPlan === plans[7].plan)
    ) {
      goodToGo = true;
    } else if (
      (signCount < 50 && userPlan === plans[2].plan) ||
      (signCount < 50 && userPlan === plans[6].plan)
    ) {
      goodToGo = true;
    } else if (
      (signCount < 20 && userPlan === plans[1].plan) ||
      (signCount < 20 && userPlan === plans[5].plan)
    ) {
      console.log(signCount + " > 4");
      goodToGo = true;
    } else if (
      (signCount < 5 && userPlan === plans[0].plan) ||
      (signCount < 5 && userPlan === plans[4].plan)
    ) {
      goodToGo = true;
    } else {
      goodToGo = false;
    }

    if (goodToGo) {
      dispatch({
        type: SET_PLAN,
        payload: userPlan,
      });
    } else {
      dispatch({
        type: CHANGE_PLAN_NEEDED,
        payload: userPlan,
      });
    }
  } catch (err) {
    console.log(err);
    const error = err.response.data.errors;

    if (error) {
      error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};
