import {
  GET_SIGNS,
  CLEAR_SIGNS,
  CLEAR_SINGLE_SIGN,
  GET_SIGN,
  SIGN_ERROR,
  UPDATE_SIGN,
  UPDATE_SIGN_ERROR,
  CREATE_SIGN,
  DELETE_SIGN,
} from "../actions/types";

const initialState = {
  signs: [],
  singleSign: null,
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SIGNS:
      return { ...state, signs: payload, loading: false };
    case GET_SIGN:
    case CREATE_SIGN:
    case UPDATE_SIGN:
      return { ...state, singleSign: payload, loading: false };
    case CLEAR_SINGLE_SIGN:
      return { ...state, singleSign: null };
    case CLEAR_SIGNS:
    case SIGN_ERROR:
    case UPDATE_SIGN_ERROR:
    case DELETE_SIGN:
      return { ...state, signs: [], loading: false };
    default:
      return state;
  }
}
