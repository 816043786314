import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_ADMIN_USER,
  GET_ADMIN_STATS,
  ADMIN_ERROR,
  GET_ADMIN_SINGLE_USER,
  ADMIN_LOADING,
  ADMIN_CANCEL_USER,
} from "./types";

//Get All Users
export const getAllUsers = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/admin");

    dispatch({
      type: GET_ADMIN_USER,
      payload: res.data.users,
    });
  } catch (error) {
    console.log(error.message);
    dispatch({
      type: ADMIN_ERROR,
      payload: "Error",
    });
  }
};

//Get All Active Users
export const getActiveUsers = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/admin/active");

    dispatch({
      type: GET_ADMIN_USER,
      payload: res.data.users,
    });
  } catch (error) {
    console.log(error.message);
    dispatch({
      type: ADMIN_ERROR,
      payload: "Error",
    });
  }
};

//Get Stats
export const getAdminStats = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/admin/stats");

    dispatch({
      type: GET_ADMIN_STATS,
      payload: res.data.stats,
    });
  } catch (error) {
    console.log(error.message);
    dispatch({
      type: ADMIN_ERROR,
      payload: "Error",
    });
  }
};

//Get User By ID
export const getUserById = (id) => async (dispatch) => {
  dispatch({ type: ADMIN_LOADING });
  try {
    const res = await axios.get(`/api/admin/${id}`);

    dispatch({
      type: GET_ADMIN_SINGLE_USER,
      payload: res.data,
    });
  } catch (error) {
    console.log(error.message);
    dispatch({
      type: ADMIN_ERROR,
      payload: "Error",
    });
  }
};

//Cancels User's plan
export const adminCancelUser = (id, sendMessage) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ id, sendMessage });

  try {
    const res = await axios.post("/api/admin/cancel", body, config);

    dispatch({
      type: ADMIN_CANCEL_USER,
      payload: res.data,
    });
  } catch (err) {
    const error = err.response.data.errors;

    if (error) {
      error.forEach((error) => dispatch(setAlert(error.msg, "danger", 5000)));
    }
    dispatch({
      type: ADMIN_ERROR,
    });
  }
};
