import React from "react";
import { Link } from "react-router-dom";
// import PropTypes from "prop-types";

const NotFound = () => {
  return (
    <div
      className="vh-100"
      style={{ maxWidth: "330px", padding: "15px", margin: "0 auto" }}
    >
      <div className="position-absolute top-50 start-50 translate-middle">
        <h1 className="large text-primary text-center">NotFound</h1>
        <p className="lead text-center">
          Well this is embarrassing. We didn't find the page you wanted.
        </p>
        <p className="text-center">
          <Link to="/">Back to the Home Page</Link>
        </p>
      </div>
    </div>
  );
};

// NotFound.propTypes = {};

export default NotFound;
