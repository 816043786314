import React from "react";
import { StripeProvider, Elements } from "react-stripe-elements";

import UpdateCard from "./UpdateCard";

const Update = () => {
  return (
    <div>
      <StripeProvider apiKey="pk_live_ZdN2N4zWoLp9l6rcXQxYoOsq">
        <Elements>
          <UpdateCard />
        </Elements>
      </StripeProvider>
    </div>
  );
};

export default Update;
