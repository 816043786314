import React, { useEffect, Fragment, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import PropTypes from "prop-types";
import { connect } from "react-redux";

import Message from "./Message";

import { getMessages } from "../../utlis/helpers";

const Messages = ({ dbMessages }) => {
  const { type } = useParams();

  const [messages, setMessages] = useState([]);
  useEffect(() => {
    setMessages(getMessages(dbMessages, type));
  }, [dbMessages, type]);

  return (
    <Fragment>
      <h1 className="display-4">Messages</h1>
      <p>
        {type ? <Link to="/messages"> All </Link> : <b>All </b>}|
        {type === "call" ? (
          <b> Calls </b>
        ) : (
          <Link to="/messages/type/call"> Calls </Link>
        )}
        |
        {type === "text" ? (
          <b> Texts </b>
        ) : (
          <Link to="/messages/type/text"> Texts </Link>
        )}
      </p>
      <div className="row">
        <div className="w-100">
          <div className="list-group">
            <span className="list-group-item list-group-item-dark disabled">
              <div className="row">
                <div className="col">
                  <b>Code</b>
                </div>
                <div className="col">
                  <b>Tracking</b>
                </div>
                <div className="col">
                  <b>When</b>
                </div>
              </div>
            </span>
            {messages.length > 0 ? (
              messages.map((message) => (
                <Message key={message._id} message={message} />
              ))
            ) : (
              <h3 className="text-center m-5">No Messages Yet.</h3>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// Messages.propTypes = {
//   // messages: PropTypes.array.isRequired,
// };

const mapStateToProps = (state) => ({
  dbMessages: state.message.messages,
});

export default connect(mapStateToProps, {})(Messages);
