import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { getPhones, setTempPhone } from "../../actions/lookup";
import { setAlert } from "../../actions/alert";
import { prettyPhone } from "../../utlis/pretty";

import { assumptions } from "../../utlis/assumptions";

const ReservePhone = ({
  getPhones,
  setTempPhone,
  setAlert,
  lookup: { numbers, loading },
  temp,
}) => {
  const [formData, setFormData] = useState({
    area: "",
  });

  const [dev, setDev] = useState(false);
  useEffect(() => {
    if (window.location.href.includes("simplesigncalls")) {
      setDev(false);
    } else {
      setDev(true);
    }
  }, []);

  const { area } = formData;

  const onChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (area.length !== 3) {
      setAlert("Search a 3 digit area code", "danger");
    } else {
      getPhones(area);
    }
  };

  if (temp) {
    setAlert(
      "You got it! Now, let's create a code to start marketing",
      "success"
    );
    return <Navigate to={assumptions.afterTempPhone} />;
  }

  return (
    <div
      className="modal modal-fullscreen fade show"
      tabIndex="-1"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-scrollable modal-fullscreen">
        <div className="modal-content">
          {/* <div className="container"> */}
          <div className="modal-header">
            <h1 className="modal-title display-4 mx-auto">
              Reserve Your Phone Number
            </h1>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-3"> </div>
              <div className="col-md-6">
                <p>
                  Choose your the phone number people will call or text. This is
                  the the '385-290-4140' in "Text DEMO to 385-290-4140."
                </p>
                <p>Start by entering the area code you want.</p>

                <form className="form" onSubmit={(e) => onSubmit(e)}>
                  <div className="form-group">
                    <input
                      type="number"
                      placeholder="Search by 3 Digit Area Code"
                      className="form-control"
                      style={{ backgroundColor: "#F8FCFF" }}
                      defaultValue={area}
                      onInput={(e) => onChange(e)}
                      name="area"
                      minLength="3"
                      maxLength="3"
                      required
                    />
                  </div>
                  <div className="d-grid gap-2">
                    <button className="btn btn-primary my-3">
                      Search Phone Numbers
                    </button>
                  </div>
                  {/* <input
          type="submit"
          className="btn btn-primary btn-block mb-3 mt-2"
          value="Search Phone Numbers"
        /> */}
                </form>
                <hr />
                {!loading && numbers.length === 0 ? (
                  <h2>No codes in that area code now, try another area code</h2>
                ) : (
                  <Fragment />
                )}
                {!loading ? (
                  <div className="list-group">
                    {/* set fake number for dev */}
                    {dev && (
                      <>
                        {}
                        <div
                          to={`/lookup/assign/+1${area}5555555`}
                          className="list-group-item list-group-item-action"
                        >
                          <div className="row">
                            <div className="col">
                              {prettyPhone(`+1${area}5555555`)}
                            </div>
                            <div className="col">
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  setTempPhone(`+1${area}5555555`);
                                }}
                              >
                                Choose Fake For Testing
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {numbers.map((number) => (
                      <div
                        key={number.phoneNumber}
                        to={`/lookup/assign/${number.phoneNumber}`}
                        className="list-group-item list-group-item-action"
                      >
                        <div className="row">
                          <div className="col">{number.friendlyName}</div>
                          <div className="col">
                            <div className="d-grid gap-2">
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  setTempPhone(`${number.phoneNumber}`);
                                }}
                              >
                                Choose
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  "Search to see available numbers"
                )}
              </div>
              <div className="col-md-3"></div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

ReservePhone.propTypes = {
  // user: PropTypes.object.isRequired,
  getPhones: PropTypes.func.isRequired,
  setTempPhone: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  lookup: state.lookup,
  temp: state.auth.temp,
});

export default connect(mapStateToProps, { getPhones, setTempPhone, setAlert })(
  ReservePhone
);
