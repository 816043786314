import React, { Fragment, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { sendReset } from "../../actions/auth";

const Reset = ({ sendReset, reset }) => {
  const [formData, setFormData] = useState({
    email: "",
  });

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    sendReset({ email });
  };

  if (reset) {
    return <Navigate to="/login" />;
  }

  return (
    <Fragment>
      <div style={{ maxWidth: "330px", padding: "15px", margin: "0 auto" }}>
        <h1 className="large text-primary">Reset Password</h1>
        <form className="form" onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <input
              type="email"
              placeholder="Email Address"
              className="form-control"
              value={email}
              onChange={(e) => onChange(e)}
              name="email"
              style={{ backgroundColor: "#F8FCFF" }}
              required
            />
          </div>
          <input
            type="submit"
            className="btn btn-primary"
            value="Get Reset Link"
          />
        </form>
        <p className="my-1">
          Don't have an account? <Link to="/register">Sign Up</Link>
          <br />
        </p>
      </div>
    </Fragment>
  );
};

Reset.propTypes = {
  reset: PropTypes.bool.isRequired,
  sendReset: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  reset: state.auth.reset,
});

export default connect(mapStateToProps, { sendReset })(Reset);
