import React, { Fragment } from "react";
import { connect } from "react-redux";
import LookUp from "./LookUp";

const StartTrial = ({ auth: { loading, user } }) => {
  //to allow a free trial, change to true
  return (
    <Fragment>
      <div style={{ maxWidth: "390px", padding: "15px", margin: "0 auto" }}>
        <p>Choose your phone number that will be on your marketing.</p>
        <LookUp nextStep="/first-sign" />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(StartTrial);
