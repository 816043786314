import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';

const Sign = ({ sign }) => {
    const { code, address, _id } = sign;
    return (
        <Fragment>
            <Link to={`/signs/${_id}`} className="list-group-item list-group-item-action">
                <div className="row">
                    <div className="col">{code} </div>
                    <div className="col">{address}</div>
                </div>
            </Link>
        </Fragment>
    )
}

export default Sign
