import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";

import { assumptions } from "../../utlis/assumptions";
import { setAlert } from "../../actions/alert";

const PlanProtected = ({ alert, setAlert, user }) => {
  const [forward, setForward] = useState(false);
  const [popup, setPopup] = useState({ msg: "", color: "dark" });

  const location = useLocation();
  let params = useParams();

  useEffect(() => {
    const checkRoute = (location, params) => {
      const { pathname } = location;
      let path = pathname;
      if (!Object.keys(params).length) {
        return pathname; // we don't need to replace anything
      }
      Object.entries(params).forEach(([paramName, paramValue]) => {
        if (paramValue) {
          path = path.replace(paramValue, `:${paramName}`);
        }
      });
      return path;
    };

    const path = checkRoute(location, params);
    if (path === "/select-plan") {
      setForward(false);
    } else if (assumptions.needPlanPages.includes(path)) {
      if (user) {
        if (user.plan) {
          if (user.plan.length < 5) {
            setPopup({ msg: "You need to choose a plan", color: "dark" });
            setForward(true);
          }
        }
      }
    }
  }, [location, params, user]);

  if (forward) {
    if (!alert.find((a) => a.msg === popup.msg)) {
      setAlert(popup.msg, popup.color);
    }
    return <Navigate to={assumptions.forwardPlan} />;
  }

  return <></>;
};

PlanProtected.propTypes = {
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  signs: state.sign.signs,
  alert: state.alert,
});

export default connect(mapStateToProps, { setAlert })(PlanProtected);
