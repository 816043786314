import { livePlans } from "./plans";

const assumptions = {
  defaultPlans: livePlans,
  freeTrial: false,
  trialLength: 14,
  afterRegister: "/reserve-phone",
  afterTempPhone: "/create-code",
  afterTempCode: "/select-plan",
  afterCheckout: "/account-set-up",
  afterPhone: "/first-sign",
  afterFirstSign: "/select-plan",
  //   upSell: false,
  //   upSellLink: "/dashboard",
  needPlanPages: ["/signs/new", "/signs/:id", "/messages/:id"],
  forwardPlan: "/select-plan",
};
export { assumptions };
