import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSign, faUserCircle } from "@fortawesome/free-solid-svg-icons";

import { prettyPhone } from "../../utlis/pretty";

const AuthNavbar = ({ auth: { user }, logout }) => {
  const [dropdown, setDropdown] = useState("none");
  const [navDrop, setNavDrop] = useState("");
  const [phone, setPhone] = useState("Loading");
  const [adminLevel, setAdminLevel] = useState(1);

  const wrapperRef = useRef(null);

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setDropdown("none");
      setNavDrop("");
    }
  };

  useEffect(() => {
    if (user) {
      if (user.phone) {
        setPhone(prettyPhone(user.phone));
      } else {
        setPhone("Not Set");
      }
      setAdminLevel(user.userLevel);
    }
  }, [user]);

  const handleClick = () => {
    dropdown === "none" ? setDropdown("block") : setDropdown("none");
  };

  const logOff = () => {
    setDropdown("none");
    setNavDrop("");
    logout();
    return <Navigate to="/" />;
  };

  const authLinks = (
    <>
      {navDrop === "" ? (
        <>
          {adminLevel > 1 && (
            <li className="nav-item">
              <Link className="nav-link" to="/admin">
                Admin
              </Link>
            </li>
          )}
          <li className="nav-item">
            <Link className="nav-link" to="/signs">
              Codes
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/messages">
              Messages
            </Link>
          </li>
          <span className="nav-item dropdown">
            <div className="dropdown right">
              <button className="btn btn-primary" onClick={() => handleClick()}>
                <FontAwesomeIcon icon={faUserCircle} size="2x" />
              </button>
              <div
                className="dropdown-content"
                style={{ display: `${dropdown}` }}
              >
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="/feedback"
                    onClick={() => {
                      setDropdown("none");
                      setNavDrop("");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Feedback
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="/settings"
                    onClick={() => {
                      setDropdown("none");
                      setNavDrop("");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Settings
                  </NavLink>
                </li>

                <div className="dropdown-divider" />
                <span className="dropdown-item">
                  <p style={{ margin: "0px 0px 0px 0px" }}>
                    <small>Your Phone</small>
                  </p>
                  {phone}
                </span>
                <div className="dropdown-divider" />
                <span
                  className="dropdown-item"
                  onClick={() => {
                    logOff();
                  }}
                  to="#!"
                  style={{ cursor: "pointer" }}
                >
                  Logout
                </span>
              </div>
            </div>
          </span>
        </>
      ) : (
        <>
          <li className="nav-item nav-link">
            <NavLink
              className="dropdown-item"
              to="/codes"
              onClick={() => {
                setDropdown("none");
                setNavDrop("");
              }}
              style={{ cursor: "pointer" }}
            >
              Codes
            </NavLink>
          </li>
          <li className="nav-item nav-link">
            <NavLink
              className="dropdown-item"
              to="/messages"
              onClick={() => {
                setDropdown("none");
                setNavDrop("");
              }}
              style={{ cursor: "pointer" }}
            >
              Messages
            </NavLink>
          </li>
          <li className="nav-item nav-link">
            <NavLink
              className="dropdown-item"
              to="/feedback"
              onClick={() => {
                setDropdown("none");
                setNavDrop("");
              }}
              style={{ cursor: "pointer" }}
            >
              Feedback
            </NavLink>
          </li>
          <li className="nav-item nav-link">
            <NavLink
              className="dropdown-item"
              to="/settings"
              onClick={() => {
                setDropdown("none");
                setNavDrop("");
              }}
              style={{ cursor: "pointer" }}
            >
              Settings
            </NavLink>
          </li>
          {/* <Route
            render={({ history }) => (
              <li
                className="nav-item nav-link"
                onClick={() => {
                  setDropdown("none");
                  setNavDrop("");
                  history.push("/signs");
                }}
                style={{ cursor: "pointer" }}
              >
                Codes
              </li>
            )}
          /> */}
          {/* <Route
            render={({ history }) => (
              <li
                className="nav-item nav-link"
                onClick={() => {
                  setDropdown("none");
                  setNavDrop("");
                  history.push("/messages");
                }}
                style={{ cursor: "pointer" }}
              >
                Messages
              </li>
            )}
          /> */}
          {/* <Route
            render={({ history }) => (
              <li
                className="nav-item nav-link"
                onClick={() => {
                  setDropdown("none");
                  setNavDrop("");
                  history.push("/feedback");
                }}
                style={{ cursor: "pointer" }}
              >
                Feedback
              </li>
            )}
          /> */}
          {/* <Route
            render={({ history }) => (
              <li
                className="nav-item nav-link"
                onClick={() => {
                  setDropdown("none");
                  setNavDrop("");
                  history.push("/settings");
                }}
                style={{ cursor: "pointer" }}
              >
                Settings
              </li>
            )}
          /> */}
          <li className="nav-item nav-link">
            <p style={{ margin: "0px 0px 0px 0px" }}>
              <small>Your Phone</small>
            </p>
            {phone}
          </li>
          <li
            className="nav-item nav-link"
            onClick={() => {
              logOff();
            }}
            style={{ cursor: "pointer" }}
          >
            Logout
          </li>
        </>
      )}
    </>
  );

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light fixed-top navbar-shrink"
        id="mainNav"
        ref={wrapperRef}
      >
        <div className="container-fluid">
          <Link className="navbar-brand js-scroll-trigger" to="/dashboard">
            <FontAwesomeIcon icon={faSign} /> Simple Sign Calls
          </Link>
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            onClick={() => {
              navDrop === "" ? setNavDrop("show") : setNavDrop("");
            }}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className={`collapse justify-content-end navbar-collapse ${navDrop}`}
            id="navbarResponsive"
          >
            <ul className="navbar-nav me-2">{authLinks}</ul>
          </div>
        </div>
      </nav>
    </>
  );
};

AuthNavbar.propTypes = {
  auth: PropTypes.object.isRequired,
  authUser: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(AuthNavbar);
