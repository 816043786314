import React from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faSign,
  faPhone,
  faStar,
  faComments,
  faEnvelope,
  faPhoneVolume,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Faq from "../faq/Faq";
import DemoGif from "../landing/DemoGif";
import businessCard from "../../img/sign-call-marketing-print.jpg";
import { faq } from "../../utlis/faq";
import Pricing from "../landing/Pricing";

const SmsTextAutoresponder = ({}) => {
  const { slug } = useParams();

  return (
    <div style={{ background: "rgb(242, 249, 255)" }} className="pb-5">
      <div className="container">
        <h3 className="text-center pt-5">
          Looking to get more leads from your signs?
        </h3>
        <h1 className="text-center display-1 fw-bold mb-3">
          Meet Simple Sign Calls.
        </h1>
        <h4 className="text-center">
          We give you the ability to customize your message and get leads into
          your CRM simply.
        </h4>
        <Link
          to={`/register?s=${slug}&b=1`}
          className="d-grid gap-2 col-6 mx-auto pt-3"
        >
          <button className="btn btn-lg btn-primary shadow p-3 rounded ">
            Get Started Now
          </button>
        </Link>
        <p className="text-center text-secondary pb-5">
          No Contracts - Cancel Anytime
        </p>

        <div className="row pb-5 ">
          <div className="col">
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ color: "#07689F" }}
            />{" "}
            Local Number Included
          </div>
          <div className="col">
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ color: "#07689F" }}
            />{" "}
            Customize SMS Text Autoresponder
          </div>
          <div className="col">
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ color: "#07689F" }}
            />{" "}
            Auto-add Leads to your CRM
          </div>
        </div>
      </div>

      <div style={{ background: "#ffffff" }}>
        <div className="container">
          <div className="row py-5">
            <div className="d-none d-lg-block m-3 col">
              <DemoGif width={"250px"} />
            </div>
            <div className="col-lg-8 text-center">
              <h4 className="section-heading">Give Our Simple System a Try</h4>
              <h2 className="section-heading ">
                <div className="d-block d-sm-none">
                  <a href="sms:+13852904140&body=demo">
                    Text DEMO to (385) 290-4140
                  </a>
                </div>
                <div className="d-none d-sm-block">
                  Text DEMO to (385) 290-4140
                </div>
              </h2>
              <hr className="my-4" />
              <p className="mb-4">
                You will receive a sms text autoresponse that can be easily
                customized with a few simple clicks.
              </p>
              <p>
                When you get started, you <b>choose your number</b>,{" "}
                <b>create any code</b> you want, and{" "}
                <b>fully customize the sms text autoresponder</b>. If someone
                calls and enters a code, it will <b>forward the caller</b> to
                the number of your choosing. You will get an email notification
                and can have it <b>automatically add to your CRM</b>. You are in
                controll of your sign calls.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-3 my-5">
        {/* <div className="mt-5">
          <div className="row gx-5">
            <div className="col-md-4 col-sm-12">
              <div className="card mb-3 shadow">
                <div className="card-body">
                  <h3 className="text-center">Clay Winder</h3>
                  <p className="text-center text-secondary">Red Sign Team</p>
                  This is some text within a card body.
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="card mb-3 shadow">
                <div className="card-body">
                  <h3 className="text-center">Clay Winder</h3>
                  <p className="text-center secondary">Red Sign Team</p>
                  This is some text within a card body.
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-122">
              <div className="card mb-3 shadow">
                <div className="card-body">
                  <p className="text-center">Clay Winder</p>
                  <p className="text-center secondary">Red Sign Team</p>
                  This is some text within a card body.
                </div>
              </div>
            </div>
          </div>
          <div className="d-grid gap-2 col-6 mx-auto py-5">
            <button className="btn btn-lg btn-primary shadow p-3 rounded ">
              Get Started Now
            </button>
            <p className="text-center text-secondary">
              No Contracts - Cancel Anytime
            </p>
          </div>
        </div> */}

        {/* <section> */}
        <div className="row my-5">
          <div className="col-lg-8">
            <h2 className="section-heading ">
              Why A SMS Text Autoresponder software?
            </h2>
            <hr className="my-4" />
            <p className="lead mb-4">
              <b>Lets face it</b> - you get tons of calls and its hard to know
              what is another sales person or which is a lead standing infront
              of your listing.
            </p>
            <p className="lead">
              <b>That is where Simple Sign Calls Comes In</b>
            </p>
            <p className="lead">
              Our system lets you avoid putting your number out there so you
              know when someone wants information on a house, they get it,
              instantly.
            </p>
          </div>
          <div className="d-none d-lg-block m-3 col">
            <img
              src={businessCard}
              className="img-fluid rounded"
              alt="Sign call code marketing"
              loading="lazy"
            />
          </div>
        </div>

        <div className="d-none d-md-block">
          <div className="shadow-sm py-3 mb-5 bg-body rounded">
            <div className="row pt-2">
              <div className="col-md-1"></div>
              <div className="col-md-2">
                <FontAwesomeIcon
                  icon={faPhone}
                  size="3x"
                  className="mx-5 mb-3"
                />
                <p className="text-center">Choose Your Number</p>
              </div>
              <div className="col-md-2">
                <FontAwesomeIcon
                  icon={faComments}
                  size="3x"
                  className="mx-5 mb-3"
                />
                <p className="text-center">
                  Set Up SMS Text Autoresponder Messsage
                </p>
              </div>
              <div className="col-md-2">
                <FontAwesomeIcon
                  icon={faSign}
                  size="3x"
                  className="mx-5 mb-3"
                />
                <p className="text-center">Custom codes to text</p>
              </div>
              <div className="col-md-2">
                <FontAwesomeIcon
                  icon={faPhoneVolume}
                  size="3x"
                  className="mx-5 mb-3"
                />
                <p className="text-center">Forward Calls</p>
              </div>
              <div className="col-md-2">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  size="3x"
                  className="mx-5 mb-3"
                />
                <p className="text-center">Email Notification</p>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>

        {/* </section> */}
        {/* <section> */}
        <h1 className="text-center pt-5">
          Simple Sign Calls lets you <br />
          get leads without answering your phone.
        </h1>
        <p className="lead text-center">
          Don't answer numbers you don't know. Leads get info, you get their
          contact info.
        </p>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <div className="card mb-3 shadow">
              <div className="card-body m-2">
                <h2 className="text-center mb-4">Without Us</h2>

                <p className="fs-5">
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    style={{ color: "#D22F25" }}
                  />{" "}
                  No Leads{" "}
                </p>
                <p className="fs-5">
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    style={{ color: "#D22F25" }}
                  />{" "}
                  Tons of Work{" "}
                </p>
                <p className="fs-5">
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    style={{ color: "#D22F25" }}
                  />{" "}
                  Unhappy Clients{" "}
                </p>
                <p className="fs-5">
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    style={{ color: "#D22F25" }}
                  />{" "}
                  Sadness{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card mb-3 shadow">
              <div className="card-body  m-2">
                <h2 className="text-center mb-4">With Us</h2>
                <p className="fs-5">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#07689F" }}
                  />{" "}
                  Stream of Leads{" "}
                </p>
                <p className="fs-5">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#07689F" }}
                  />{" "}
                  Less Work
                </p>
                <p className="fs-5">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#07689F" }}
                  />{" "}
                  Happy Buyers & Sellers
                </p>
                <p className="fs-5">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#07689F" }}
                  />{" "}
                  Opposite of Sadness{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
        {/* </section> */}
        <h2 className="text-center mt-5">
          Do you like feeling the opposite of sadness?
        </h2>
        <h1 className="text-center">
          Then Sign Up and Give Simple Sign Calls a Try
        </h1>
        <Link
          to={`/register?s=${slug}&b=2`}
          className="d-grid gap-2 col-6 mx-auto pt-3"
        >
          <button className="btn btn-lg btn-primary shadow p-3 rounded ">
            Get Started Now
          </button>
        </Link>
        <p className="text-center text-secondary pb-5">
          No Contracts - Cancel Anytime
        </p>
      </div>

      <hr className="my-5" />

      <div className="pt-5">
        <div className="d-flex justify-content-center">
          <FontAwesomeIcon
            icon={faStar}
            style={{ color: "#07689F" }}
            size="2x"
          />
          <FontAwesomeIcon
            icon={faStar}
            style={{ color: "#07689F" }}
            size="2x"
          />
          <FontAwesomeIcon
            icon={faStar}
            style={{ color: "#07689F" }}
            size="2x"
          />
          <FontAwesomeIcon
            icon={faStar}
            style={{ color: "#07689F" }}
            size="2x"
          />
          <FontAwesomeIcon
            icon={faStar}
            style={{ color: "#07689F" }}
            size="2x"
          />
        </div>
        <h1 className="text-center ">Top Agents Love Simple Sign Calls</h1>
        <h3 className="text-center mb-5">See what they are saying</h3>
        <div className="container">
          <div className="card mb-5">
            <div className="card-header">
              <h3>Red Sign Team's Story...</h3>
            </div>
            <div className="card-body">
              <p className="lead">
                The Red Sign Team got started with Simple Sign Calls when they
                had 14 agents and have been growing ever since.{" "}
              </p>
              <p className="lead">
                They wanted a service that they could{" "}
                <b>customize the sms text autoresponder</b> that texts back and
                a way to <b>route leads to the right agent</b> and to their CRM.
              </p>
              <p className="lead">
                "We have spent a lot of money, time, and effort to drive traffic
                to our website," says Clay Winder the owner of the team, "but
                most services have their own website. We want the control to
                send a link of the house that goes right to our website, or a
                list of other homes nearby if the home is under contract. Plus
                we can choose who gets the lead either with an email
                notification or having it added automatically to our CRM.{" "}
                <b>
                  Simple Sign Calls gives us complete control to market how we
                  want
                </b>
                ."{" "}
              </p>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h3>Jason's Story</h3>
            </div>
            <div className="card-body">
              <p className="lead">
                Jason Koford was on various teams before he decided to go out on
                his own and build his own business.
              </p>
              <p className="lead">
                "I love that I don't have to put my number on my marketing or
                have to answer all my calls. As a realtor, I get a lot of spam
                calls. So I don't want to answer my phone for every unknown
                number but I want to <b>collect leads from sign calls</b>.
                Simple Sign Calls has allowed me to have a number to put on my
                marketing. I get an email <b>notification of every lead</b>{" "}
                calling or texting on the number so even if I can't (or don't
                want to) answer my phone, I can collect leads. Plus with the
                customizable sms text autoresponder, when people want info, they
                can get it instantly. "
              </p>
            </div>
          </div>
        </div>
        <h1 className="text-center pt-4">Start Your Simple Sign Call Story</h1>
        <Link
          to={`/register?s=${slug}&b=3`}
          className="d-grid gap-2 col-6 mx-auto pt-3"
        >
          <button className="btn btn-lg btn-primary shadow p-3 rounded ">
            Get Started Now
          </button>
        </Link>
        <p className="text-center text-secondary pb-5">
          No Contracts - Cancel Anytime
        </p>
      </div>

      <div>
        <Pricing />
        <Link
          to={`/register?s=${slug}&b=4`}
          className="d-grid gap-2 col-6 mx-auto pt-3"
        >
          <button className="btn btn-lg btn-primary shadow p-3 rounded ">
            Get Started Now
          </button>
        </Link>
        <p className="text-center text-secondary pb-5">
          No Contracts - Cancel Anytime
        </p>
      </div>

      <div className="container">
        <h1 className="text-center pt-4">Frequently Asked Questions</h1>
        <div id="accordion">
          {faq.map((f) => (
            <Faq key={f.id} faq={f} />
          ))}
        </div>
      </div>
    </div>
  );
};

SmsTextAutoresponder.propTypes = {};

export default SmsTextAutoresponder;
