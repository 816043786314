import React, { Fragment, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    login(email, password);
  };

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Fragment>
      <div style={{ maxWidth: "330px", padding: "15px", margin: "0 auto" }}>
        <h1 className="large text-primary">Sign In</h1>
        <p className="lead">Sign Into Your Account</p>
        <form className="form" onSubmit={(e) => onSubmit(e)}>
          <div className="form-floating mb-3">
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => onChange(e)}
              style={{ backgroundColor: "#F8FCFF" }}
            />
            <label htmlFor="email">Email address</label>
          </div>

          <div className="form-floating mb-3">
            <input
              type="password"
              placeholder="Password"
              name="password"
              className="form-control"
              value={password}
              onChange={(e) => onChange(e)}
              style={{ backgroundColor: "#F8FCFF" }}
            />
            <label htmlFor="password">Password</label>
          </div>
          <div className="d-grid">
            <input
              type="submit"
              className="btn btn-primary btn-block"
              value="Sign In"
            />
          </div>
        </form>
        <p className="my-1">
          Don't have an account? <Link to="/register">Sign Up</Link>
          <br />
          <Link to="/reset">Forgot password? </Link>
        </p>
      </div>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
