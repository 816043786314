const messageCount = (messages, type) => {
  if (messages.length > 0) {
    const filtered = messages.filter((m) => m.type === type);
    return filtered.length;
  } else {
    return 0;
  }
};

const signCount = (signs) => {
  if (signs.length > 0) {
    return signs.length;
  } else {
    return 0;
  }
};

const findSign = (signs, id) => {
  if (signs.length > 0) {
    const sign = signs.filter((sign) => sign._id === id);
    return sign[0];
  } else {
    return null;
  }
};
const findSignByCode = (signs, code) => {
  if (signs.length > 0) {
    const sign = signs.filter((sign) => sign.code === code);
    if (sign.length > 0) {
      return sign[0];
    }
  }
  return null;
};
const findMessage = (messages, id) => {
  if (messages.length > 0) {
    const message = messages.filter((m) => m._id === id);
    return message[0];
  } else {
    return null;
  }
};

const messagesBySign = (messages, code) => {
  if (messages.length > 0) {
    return messages.filter((m) => m.code === code);
  } else {
    return [];
  }
};

const getMessages = (messages, type) => {
  if (type) {
    return messages.filter((m) => m.type === type);
  } else {
    return messages;
  }
};

export {
  messageCount,
  signCount,
  findSign,
  findSignByCode,
  messagesBySign,
  findMessage,
  getMessages,
};
