import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { clearSingleSign } from "../../actions/sign";
import Sign from "./Sign";
// import UpdateCheck from "../stripe/UpdateCheck";

const Signs = ({ signs, clearSingleSign }) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <h1 className="display-4">Codes</h1>
        </div>
        <div className="col">
          <div className="float-end mt-3">
            <Link
              to="/signs/new"
              onClick={() => {
                clearSingleSign();
              }}
            >
              <button className="btn btn-primary">Add Code</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="w-100">
          <div className="list-group">
            <span className="list-group-item list-group-item-dark disabled">
              <div className="row">
                <div className="col">
                  <b>Code</b>
                </div>
                <div className="col">
                  <b>Tracker</b>
                </div>
              </div>
            </span>
            {signs.length > 0 ? (
              signs.map((sign) => <Sign key={sign._id} sign={sign} />)
            ) : (
              <h3 className="text-center m-5">
                No codes found. <Link to="/signs/new">Create One</Link>.
              </h3>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

Signs.propTypes = {
  // signs: PropTypes.array.isRequired,
  clearSingleSign: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  signs: state.sign.signs,
});

export default connect(mapStateToProps, { clearSingleSign })(Signs);
