import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { solutions } from "../../utlis/solutions";

const SolutionMain = () => {
  return (
    <Fragment>
      <h1 className="display-4">Using The Sign Rider System</h1>
      <p>
        Just because this was built for generate and convert more leads on your
        real estate signs doesn't mean that is the only way you can capitalize
        on the sign call system. Here are a few ways that some of our other real
        estate agents are using this simple sign call system.
      </p>

      {solutions.map(solution => (
        <Fragment key={solution.id}>
          <div className="card flex-md-row mb-4 box-shadow">
            <div className="card-body d-flex flex-column align-items-start">
              <h3>{solution.title}</h3>
              <p className="card-text mb-auto">
                {solution.discription
                  .substr(0, 250)
                  .replace("<p>", "")
                  .replace("</p>", "")}
                <Link to={"/solutions/" + solution.slug}>...</Link>
              </p>
              <Link to={"/solutions/" + solution.slug}>
                Read More About {solution.title}
              </Link>
            </div>
          </div>
        </Fragment>
      ))}
      <p>
        Learn more on how to take advantage of your sign calls using our{" "}
        <Link to="/">real estate text rider system</Link>.
      </p>
    </Fragment>
  );
};

export default connect()(SolutionMain);
