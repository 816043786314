import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

const AdminCheck = ({ user }) => {
  const [forward, setForward] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.userLevel <= 3) {
        setForward(true);
      }
    }
  }, [user]);

  if (forward) {
    return <Navigate to="/dashboard" />;
  }

  return <div></div>;
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(AdminCheck);
