export const solutions = [
  {
    id: 0,
    title: "FSBO Marketing Kit",
    discription: `
    <p>One of the best things you can do in real estate is to put your sign up everywhere. It is a tiny billboard marketing you! For this there are many agnets that use a FSBO Market Kit. This is where you give For Sale By Owners a little help so you get buyer leads and then if they can sell their house, you are positioned to swoop in and help.</p>
    <p>Our agents have been using our real estate text rider system to maximize capturing every lead from the sign.</p>
    <p>The sign rider system is set up to be easily changed from house to house. So you can easily change the sign from one FSBO to another. This will help you take control of your real estate signs and convert more leads.</p>
    `,

    slug: "fsbo-marketing-kit"
  },
  {
    id: 1,
    title: "Craigslist Marketing",
    discription: `
    <p>Craigslist and other online classified sites are a great place to put your listings to get exposure. But there is no way to forces people to register for more information. You end up letting people see all the photos, description, and you only get leads that are very interesting.<p>
    <p>Imagine doing things differently... Tell everyone on craigslist to text a code to your phone number and they will get all the information that way (or a link to your website). That way you get leads for people that are semi-interested and not just those that want to see it.</p>
    <p>Its a very simple way to maximize leads. You can do this for any online source to make sure you capture leads' information so you can be proactive in calling people rather than reactive, hoping they will call you</p>
    `,
    slug: "fsbo-marketing-kit"
  },
  {
    id: 2,
    title: "Lead Tracking",
    discription: `
    <p>You have yard signs, online ads, flyers, door hangers, etc and you get a call. Which marketing source is working? Don't worry, we got this. By adding your phone number and a different code for each marketing source you can easily track which source gave you which leads.</p>
    <p>Take it even a lever further and test the message you send for each source to work on optimizing every lead source for every listing. Don't work harder, work smarter.</p>
    `,
    slug: "lead-tracking"
  }
];
