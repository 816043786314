import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate, useHref } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import { livePlans, devPlans, grandfatherPlans } from "../../utlis/plans";
import { signCount as getSignCount } from "../../utlis/helpers";
import { assumptions } from "../../utlis/assumptions";

const UpdateCheck = ({ user, signs, setAlert }) => {
  const url = useHref();

  const [forward, setForward] = useState(false);
  const [link, setLink] = useState("/select-plan");

  const isDateGreater = (d1, d2, days) => {
    d1 = new Date(d1);
    return +new Date(d2) > d1.setDate(d1.getDate() + (days || 0));
  };

  const [plans, setPlans] = useState([]);
  useEffect(() => {
    if (window.location.href.includes("simplesigncalls")) {
      if (user) {
        if (user.planList === "grandfather") {
          setPlans(grandfatherPlans);
        } else {
          setPlans(livePlans);
        }
      } else {
        setPlans(livePlans);
      }
    } else {
      setPlans(devPlans);
    }
  }, [user]);

  const [popup, setPopup] = useState({ msg: "", color: "dark" });

  useEffect(() => {
    if (user && plans.length > 0) {
      const signCount = getSignCount(signs);
      if (user.plan === "free") {
        if (assumptions.freeTrial) {
          if (signCount >= 5) {
            setPopup({
              msg: "You've hit your code limit. Select a plan to create another code",
              color: "dark",
            });
            setForward(true);
          }
          const trialLength = assumptions.trialLength;
          if (isDateGreater(user.timestamp, new Date(), trialLength - 1)) {
            setPopup({
              msg: `Your ${trialLength} trial has come to an end... Time to choose a plan.`,
              color: "dark",
            });
            setForward(true);
          }
        } else {
          // setPopup({ msg: "Plan isn't set. Choose your plan", color: "dark" });
          // setForward(true);
        }
      } else {
        const planIndex = plans.findIndex((plan) => plan.plan === user.plan);
        if (plans.length > 0) {
          if (planIndex === -1) {
            setPopup({
              msg: "Plan isn't set. Choose your plan",
              color: "dark",
            });
            setForward(true);
          }
        }

        const plan = plans[planIndex];
        if (plan) {
          if (url === "/signs/new" && signCount === plan.codes) {
            setPopup({
              msg: "You've hit your code limit. Upgrade your plan to create another code",
              color: "dark",
            });
            setLink("/update-plan");
            setForward(true);
          }
          if (signCount > plan.codes) {
            setPopup({
              msg: "You've hit your code limit. Upgrade your plan to create another code",
              color: "dark",
            });
            setLink("/update-plan");
            setForward(true);
          }
        }
      }
    }
  }, [user, signs, url, setAlert, plans]);

  if (forward) {
    setAlert(popup.msg, popup.color);
    return <Navigate to={link} />;
  }

  return <div></div>;
};

UpdateCheck.propTypes = {
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  signs: state.sign.signs,
});

export default connect(mapStateToProps, { setAlert })(UpdateCheck);
