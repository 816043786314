import uuid from "uuid";
import { SET_ALERT, REMOVE_ALERT, CLEAR_ALERTS } from "./types";

export const setAlert =
  (msg, alertType, time = 5000) =>
  (dispatch) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    const id = uuid.v4();
    dispatch({
      type: SET_ALERT,
      payload: {
        msg,
        alertType,
        time,
        id,
      },
    });

    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), time);
  };

export const clearAlerts = () => (dispatch) => {
  dispatch({
    type: CLEAR_ALERTS,
  });
};
