import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const Message = ({ message }) => {
  const { code, address, _id, timestamp } = message;
  return (
    <Fragment>
      <Link
        to={`/messages/${_id}`}
        className="list-group-item list-group-item-action"
      >
        <div className="row">
          <div className="col">{code} </div>
          <div className="col">{address}</div>
          <div className="col">
            <Moment format="D MMM YY H:mm A">{timestamp}</Moment>
          </div>
        </div>
      </Link>
    </Fragment>
  );
};

export default Message;
