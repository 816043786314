import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setPlan } from "../../actions/stripe";
import { setAlert } from "../../actions/alert";
import { injectStripe, CardElement } from "react-stripe-elements";
import ReactGA from "react-ga4";
import { assumptions } from "../../utlis/assumptions";

const CheckoutForm = ({
  planId,
  planName,
  email,
  setPlan,
  stripe,
  setAlert,
}) => {
  const [checkout, setCheckout] = useState({ paid: false });
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (stripe) {
      const { token, error } = await stripe.createToken();
      if (!error) {
        setCheckout({ paid: true });
        ReactGA.event({
          category: "Other",
          action: "Set up payment",
          value: 15,
        });
        await setPlan(token.id, planId, email);
        setCheckout({ paid: false });
        setSubmitted(false);
      } else {
        setSubmitted(false);
        setAlert(error.message, "danger");
      }
    } else {
      setAlert("Payment system did not load. Try again later", "danger");
    }
    setSubmitted(false);
  };

  const createOptions = () => {
    return {
      style: {
        base: {
          fontSize: "18px",
          color: "#424770",
          letterSpacing: "0.025em",
          // backgroundColor: "white",
          // padding: "20px",
          // margin: "20px",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#050608",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    };
  };

  return (
    <div className="checkout">
      {/* <p>
        Sign up for the {planName} and upgrade, downgrade, or cancel at anytime.
        We'll start you at the plan with 5 keywords. You can upgrade if you need
        more.
      </p>
      <ul>
        <li>20 Keywords starting at $33.33 per month</li>
        <li>50 Keywords starting at $62.50 per month</li>
        <li>100 Keywords starting at $104.17 per month</li>
      </ul>
      <p>Phone number included!</p> */}
      {/* <h3 className="mb-3">Checkout</h3> */}

      {/* <div className="card text-dark bg-light m-3">
        <div className="card-body text-center">
          <span className="text-center">
            Text 1234 to 801-801-8011 for instant Info
          </span>
        </div>
      </div> */}
      <p className="mb-5">Get your phone number and keyword working!</p>
      {/* <h3 style={{ textAlign: "center", marginBottom: "40px" }}>
        Sign up for the {planName} plan
      </h3> */}
      <form onSubmit={(e) => onSubmit(e)}>
        <fieldset className="FormGroup">
          <div className="FormRow">
            <CardElement {...createOptions()} />
          </div>
        </fieldset>
        <div className="d-grid">
          {checkout.paid ? (
            <button className="btn btn-primary btn-lg mt-3" disabled>
              {assumptions.freeTrial ? "Start Trial" : "Sign Up"}
            </button>
          ) : (
            <button
              className="btn btn-primary btn-lg mt-3"
              disabled={submitted}
              // style={{ marginTop: "40px" }}
            >
              {submitted ? (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Submitting...</span>
                </div>
              ) : (
                "Start 14 Day Free Trial"
              )}
            </button>
          )}
        </div>
        <p style={{ textAlign: "center" }}>90 Day Money Back Guarantee</p>
      </form>
    </div>
  );
};

CheckoutForm.propTypes = {
  setPlan: PropTypes.func.isRequired,
  stripe: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  temp: state.temp,
});

export default connect(mapStateToProps, { setPlan, setAlert })(
  injectStripe(CheckoutForm)
);
