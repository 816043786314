import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { sendFeedback } from "../../actions/settings";
import Spinner from "../layout/Spinner";

const Feedback = ({ auth: { loading, user }, sendFeedback }) => {
  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    message: "",
  });
  const { email, subject, message } = formData;

  useEffect(() => {
    if (user) {
      setFormData({
        email: loading || !user.email ? "" : user.email,
        subject: formData.subject,
        message: formData.message,
      });
    }
  }, [user, loading, formData]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    sendFeedback(email, subject, message);
  };

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <div>
            <h1 className="display-4">Contact Us</h1>
            <form className="form" onSubmit={(e) => onSubmit(e)}>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  style={{ backgroundColor: "#F8FCFF" }}
                  value={email}
                  onChange={(e) => onChange(e)}
                  required
                />
                <label htmlFor="email">Your Email</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="subject"
                  className="form-control"
                  placeholder="Subject"
                  value={subject}
                  onChange={(e) => onChange(e)}
                  style={{ backgroundColor: "#F8FCFF" }}
                  required
                />
                <label htmlFor="subject">Subject</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  style={{ height: "250px", backgroundColor: "#F8FCFF" }}
                  placeholder="Message"
                  onChange={(e) => onChange(e)}
                  value={message}
                  required
                />
                <label htmlFor="message">Message</label>
              </div>
              <div className="d-grid">
                <input type="submit" className="btn btn-primary" value="Send" />
              </div>
            </form>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

Feedback.propTypes = {
  sendFeedback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { sendFeedback })(Feedback);
