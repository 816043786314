import axios from "axios";
import { SET_SEARCH, SEARCH_ERROR } from "../actions/types";

export const sendSearch = ({ query, field }) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ query, field });
  try {
    const res = await axios.post("/api/search", body, config);
    dispatch({
      type: SET_SEARCH,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: SEARCH_ERROR
    });
  }
};
