import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { StripeProvider, Elements } from "react-stripe-elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import CheckoutForm from "./CheckoutForm";
import Spinner from "../layout/Spinner";
import { devPlans } from "../../utlis/plans";
import { assumptions } from "../../utlis/assumptions";

const Checkout2 = ({ auth: { loading, user } }) => {
  const [theUser, setTheUser] = useState({
    email: "",
    plan: null,
  });
  const [plan, setThePlan] = useState("monthly");
  const trial = assumptions.freeTrial;
  const [plans, setPlans] = useState(devPlans);
  const [stripeApi, setStripeApi] = useState(
    "pk_live_ZdN2N4zWoLp9l6rcXQxYoOsq"
  );
  useEffect(() => {
    if (window.location.href.includes("simplesigncalls")) {
      setPlans(assumptions.defaultPlans);
      setStripeApi("pk_live_ZdN2N4zWoLp9l6rcXQxYoOsq");
    } else {
      setPlans(devPlans);
      setStripeApi("pk_test_Khx7hkeU1SvJXF80fxthA55g");
    }
  }, []);

  useEffect(() => {
    setTheUser(
      user === null
        ? {}
        : {
            email: user.email,
            plan: user.plan,
          }
    );
  }, [user]);

  const onClick = (currentPlan) => {
    if (currentPlan === "monthly") {
      setThePlan("yearly");
    } else {
      setThePlan("monthly");
    }
  };

  const [monthly, setMonthly] = useState({ price: 15 });
  const [yearly, setYearly] = useState({ price: 150 });
  useEffect(() => {
    const monthyIndex = plans.findIndex(
      (plan) => plan.minCodes === 0 && plan.time === "monthly"
    );
    if (monthyIndex > -1) {
      setMonthly(plans[monthyIndex]);
    }
    const yearlyIndex = plans.findIndex(
      (plan) => plan.minCodes === 0 && plan.time === "yearly"
    );
    if (yearlyIndex > -1) {
      setYearly(plans[yearlyIndex]);
    }
  }, [plans]);
  // const monthly = plans[0].plan; //"plan_DNSuZ60iLzpBqi";
  // const yearly = plans[4].plan; //"plan_DX7QJoGv7jcuoA";

  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    if (theUser.plan) {
      const found = plans.filter((plan) => plan.plan === theUser.plan);
      if (found.length > 0) {
        setRedirect(true);
      }
    }
  }, [theUser, plans]);

  if (redirect) {
    return <Navigate to={assumptions.afterCheckout} />;
  }

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div
            className="modal modal-fullscreen show"
            tabIndex="-1"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-scrollable modal-fullscreen">
              <div className="modal-content">
                {/* <div className="modal-body"> */}
                <div className="row" style={{ minHeight: "100vh" }}>
                  <div
                    className="col-md-6 py-5 v-100"
                    style={{ background: "#07689F" }}
                  >
                    <div
                      className="container px-5 text-white"
                      // style={{ minHeight: "100vh" }}
                    >
                      {/* <p className="pb-5">{"<"} Back To Learn More</p> */}
                      <h1 className="display-4">Checkout</h1>
                      {/* <small className="pb-5 lh-1">
                        Upgrade/downgrade anytime
                      </small> */}

                      {/* Plan Info */}
                      <div className="row pt-3">
                        <div className="col-2">
                          <FontAwesomeIcon icon={solid("sign")} size="3x" />
                        </div>
                        <div className="col-7">
                          <p className="">Custom Phone Number & 5 Keywords</p>
                        </div>
                        <div className="col-3 text-end">
                          <span className="fs-2">
                            {plan === "monthly"
                              ? monthly.price.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                              : (yearly.price / 12).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}{" "}
                          </span>
                          <br />
                          <span className="fs-6 fw-light">Per Month</span>
                        </div>
                      </div>
                      <div
                        className="card my-3"
                        style={{ background: "#1f77a8" }}
                      >
                        <div className="card-body">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input form-check-input-color"
                              type="checkbox"
                              role="switch"
                              id="plan"
                              name="plan"
                              onChange={() => {
                                onClick(plan);
                              }}
                            />
                            <label className="form-check-label" htmlFor="plan">
                              Get 2 Months Free with Yearly Plan
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-2"></div>
                        <div className="col-7">Total Due Today</div>
                        <div className="col-3">
                          <span className="fs-2">
                            {plan === "monthly"
                              ? monthly.price.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                              : yearly.price.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 py-5">
                    <div className="container">
                      <StripeProvider apiKey={stripeApi}>
                        <Elements>
                          <CheckoutForm
                            planId={plan === "monthly" ? monthly : yearly}
                            planName={plan}
                            email={theUser.email}
                          />
                        </Elements>
                      </StripeProvider>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

Checkout2.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Checkout2);
