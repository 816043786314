import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getAllUsers } from "../../actions/admin";
import { planCostById, planMonthlyIncome } from "../../utlis/plans";
import Spinner from "../layout/Spinner";
import AdminCheck from "../../utlis/AdminCheck";
import Card from "../dashboard/Card";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { prettyPhone } from "../../utlis/pretty";

const AdminDash = ({ getAllUsers, admin: { users, loading } }) => {
  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  const [userCount, setUserCount] = useState(0);
  const [income, setIncome] = useState(0.0);

  useEffect(() => {
    if (users.length > 0) {
      let total = 0;
      let count = 0;
      users.forEach((u) => {
        if (u.plan && u.plan !== "free") {
          if (planMonthlyIncome(u.plan)) {
            total += parseFloat(planMonthlyIncome(u.plan));
            count += 1;
          }
        }
      });
      setIncome(total.toFixed(2));
      setUserCount(count);
    }
  }, [users, setUserCount, setIncome]);

  return (
    <Fragment>
      <AdminCheck />
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <h1 className="display-4">Admin</h1>
          <div
            className="row d-flex justify-content-center"
            style={{ padding: "1em" }}
          >
            <div className="row">
              <div className="col">
                <Card
                  icon={<FontAwesomeIcon icon={faUser} size="5x" />}
                  name="Paying Users"
                  link="/admin"
                  count={userCount}
                  color="blue"
                />
              </div>
              <div className="col">
                <Card
                  icon={<FontAwesomeIcon icon={faMoneyBill} size="5x" />}
                  name="Revenue"
                  link="/admin"
                  count={`$${income}`}
                  color="green"
                />
              </div>
            </div>
          </div>
          {users && (
            <Fragment>
              <h1 className="display-6">All Active Users</h1>
              <div className="d-grid">
                <Link to="/admin/user/add">
                  <button className="btn btn-primary btn-block mb-3 mt-2">
                    Add New User
                  </button>
                </Link>
              </div>
              <div className="list-group">
                {users.map((u, i) => (
                  <Fragment key={i}>
                    {u.plan && (
                      <Link
                        to={`/admin/user/${u._id}`}
                        className="list-group-item list-group-item-action"
                      >
                        <div className="row">
                          <div className="col">{u.email} </div>
                          <div className="col">
                            {u.phone && prettyPhone(u.phone)}
                          </div>
                          <div className="col">
                            {u.plan === "free" ? (
                              <>Free</>
                            ) : (
                              <>${u.plan && planCostById(u.plan)}</>
                            )}
                          </div>
                        </div>
                      </Link>
                    )}
                  </Fragment>
                ))}
              </div>
              <hr />
              <h1 className="display-6">In-Active Users</h1>
              <div className="list-group">
                {users.map((u, i) => (
                  <Fragment key={i}>
                    {!u.plan && (
                      <Link
                        to={`/admin/user/${u._id}`}
                        className="list-group-item list-group-item-action"
                      >
                        <div className="row">
                          <div className="col">{u.email} </div>
                          <div className="col">
                            {u.phone && prettyPhone(u.phone)}
                          </div>
                        </div>
                      </Link>
                    )}
                  </Fragment>
                ))}
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

AdminDash.propTypes = {
  getAllUsers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});

export default connect(mapStateToProps, {
  getAllUsers,
})(AdminDash);
