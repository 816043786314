import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { createSign, clearSigns } from "../../actions/sign";
import UpdateCheck from "../stripe/UpdateCheck";
import { prettyPhone } from "../../utlis/pretty";
import { setAlert } from "../../actions/alert";
// import { ToolTip } from "../layout/ToolTip";

const SignCreate = ({
  createSign,
  auth: { loading, user },
  signs: { singleSign },
  stripe,
  first = false,
  setAlert,
}) => {
  const [formData, setFormData] = useState({
    code: "",
    message: "",
    notify: "",
    forward: "",
    address: "",
  });

  const [theSign, setTheSign] = useState({
    id: null,
  });

  const [theUser, setTheUser] = useState({
    phone: "+1555555555",
    forwardPhone: "",
    loadingUser: true,
  });
  const [textNum, setTextNum] = useState({ textCount: 1 });
  const { textCount } = textNum;

  const [needUpdate, setNeedUpdate] = useState({
    needsAttention: false,
  });

  useEffect(() => {
    if (singleSign) {
      setTheSign({
        id: loading || !singleSign._id ? null : singleSign._id,
      });
    }
  }, [singleSign, loading]);

  useEffect(() => {
    setTheUser({
      phone: loading || !user.phone ? null : user.phone,
      loadingUser: loading || !user ? true : false,
      forwardPhone: loading || !user.forwardPhone ? null : user.forwardPhone,
    });

    user && setFormData({ ...formData, forward: user.forwardPhone });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

  useEffect(() => {
    setNeedUpdate({
      needsAttention: loading || !needUpdate ? false : stripe.needsAttention,
    });
  }, [stripe, needUpdate, loading]);

  const { code, message, notify, forward, address, unit, state, zip } =
    formData;

  let textLen;
  const onChange = (e) => {
    message ? (textLen = Math.ceil(message.length / 160)) : (textLen = 1);
    setTextNum({ ...textNum, textCount: textLen });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const formatPhone = (e) => {
    let phone;
    const input = e.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if (input.length > 6) {
      phone = `(${zip}) ${middle}-${last}`;
    } else if (input.length > 3) {
      phone = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      phone = `(${zip}`;
    }
    setFormData({ ...formData, forward: phone });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    await createSign({
      code,
      message,
      notify,
      forward,
      address,
      unit,
      state,
      zip,
    });
  };

  // if (needUpdate.needsAttention) {
  //   if (user) {
  //     if (user.plan !== "free") {
  //       return <Navigate to="/update-plan" />;
  //     }
  //   }
  // }

  if (user) {
    if (!user.phone || user.phone.length < 10) {
      setAlert("You need to set up a phone number first", "dark");
      return <Navigate to="/look-up" />;
    }
  }

  if (theSign.id) {
    if (first) {
      const link = "/test/" + theSign.id;
      return <Navigate to={link} />;
    } else {
      const link = "/signs/" + theSign.id;
      return <Navigate to={link} />;
    }
  }

  return (
    <>
      {!first && (
        <>
          <UpdateCheck />
        </>
      )}
      <div className="row">
        <div className="container">
          <h1 className="display-4">Create Your {first && <>First</>} Code</h1>
          {first && (
            <p>
              This code will be used on your signs and other marketing
              materials. Leads will use this code to text or call for info, so
              make it easy to type. All info but the code can be edited later.
            </p>
          )}
          <form className="form" onSubmit={(e) => onSubmit(e)}>
            {first && (
              <p className="pt-3">
                This is the code that will be entered when they text or call.
                It's the 1234 in "Text 1234 to{" "}
                {theUser.phone ? prettyPhone(theUser.phone) : "(555) 555-5555"}"
              </p>
            )}
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="code"
                name="code"
                value={code}
                onChange={(e) => onChange(e)}
                pattern="[A-Za-z0-9]{1,10}"
                required
                style={{ backgroundColor: "#F8FCFF" }}
                title="Code must be 1-10 characters"
                placeholder="12345"
              />
              {/* <span
                style={{
                  position: "relative",
                  zIndex: 2,
                  float: "right",
                  marginRight: "5px",
                  marginTop: "-30px",
                }}
                >
                <ToolTip title="The code or keyword someone will text" />
              </span> */}
              <label htmlFor="code">Text Code</label>
              <small>We recommend using numbers only for ease of use.</small>
            </div>
            {first && (
              <p className="mt-5">
                What do you want the to be auto responded when your code is
                text?
              </p>
            )}
            <div className="form-floating mb-3">
              <textarea
                className="form-control"
                id="message"
                name="message"
                onChange={(e) => onChange(e)}
                value={formData.message}
                style={{ backgroundColor: "#F8FCFF", height: "250px" }}
                placeholder="Thanks for texting..."
                required
              />
              {/* <span
                style={{
                  position: "relative",
                  zIndex: 2,
                  float: "right",
                  marginRight: "5px",
                  marginTop: "-30px",
                }}
              >
                <ToolTip
                  title="The message a client gets when the above code or keyword is
                    text to your number"
                />
              </span> */}

              <label htmlFor="message">Message </label>
              <small>
                <span id="charNum" />
                Your message will be sent in {textCount} messages
              </small>
            </div>
            {first && (
              <p className="mt-5">
                After someone calls or texts, what email should get a
                notification of the person's name (from call ID), phone number,
                and on which code.
              </p>
            )}
            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                id="notify"
                name="notify"
                value={notify}
                onChange={(e) => onChange(e)}
                placeholder="me@myemail.com"
                style={{ backgroundColor: "#F8FCFF" }}
              />
              {/* <span
                style={{
                  position: "relative",
                  zIndex: 2,
                  float: "right",
                  marginRight: "5px",
                  marginTop: "-30px",
                }}
              >
                <ToolTip title="What email should get a notification that there is a lead." />
              </span> */}
              <label htmlFor="notify">Notify Email Address </label>
              <small className="mb-3">
                Optional - enter an email to notify of a call or text. You can
                add more emails later.
              </small>
            </div>
            {first && (
              <p className="pt-5">
                If someone calls the phone number and enters in the code, who
                should the call be forwarded to? By the way, this only works if
                the code is only numbers.
              </p>
            )}
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="forward"
                name="forward"
                value={forward}
                onChange={(e) => formatPhone(e)}
                placeholder="555-555-5555"
                style={{ backgroundColor: "#F8FCFF" }}
                required
              />
              {/* <span
                style={{
                  position: "relative",
                  zIndex: 2,
                  float: "right",
                  marginRight: "5px",
                  marginTop: "-30px",
                }}
              >
                <ToolTip title="What phone should we forward calls to? *this only works if the code is all numbers." />
              </span> */}
              <label htmlFor="forward">Call Forward </label>
            </div>
            {/* <div className="row"> */}
            {first && (
              <p className="pt-5">
                This is just for you. Enter in something so you can remember
                what code this is for.
              </p>
            )}
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                value={address}
                onChange={(e) => onChange(e)}
                placeholder="123 Main St"
                style={{ backgroundColor: "#F8FCFF" }}
              />
              {/* <span
                style={{
                  position: "relative",
                  zIndex: 2,
                  float: "right",
                  marginRight: "5px",
                  marginTop: "-30px",
                }}
              >
                <ToolTip title="For tracking purposes, so you know on which home someone called or text." />
              </span> */}
              <label htmlFor="address">Tracking </label>
            </div>

            <div className="d-grid gap-2">
              <button type="submit" className="btn btn-primary">
                Create Code
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

SignCreate.propTypes = {
  createSign: PropTypes.func.isRequired,
  clearSigns: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  signs: state.sign,
  stripe: state.stripe,
});

export default connect(mapStateToProps, { createSign, clearSigns, setAlert })(
  SignCreate
);
