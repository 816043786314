import React, { Fragment } from "react";
import SignCreate from "./SignCreate";

const FirstSign = () => {
  return (
    <Fragment>
      <SignCreate first={true} />
    </Fragment>
  );
};

export default FirstSign;
