import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSign } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  // const [dropdown, setDropdown] = useState("none");
  const [navDrop, setNavDrop] = useState("");

  const wrapperRef = useRef(null);

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      // setDropdown("none");
      setNavDrop("");
    }
  };

  const guestLinks = (
    <>
      <li>
        <NavLink
          to="/register"
          className="nav-item nav-link"
          onClick={() => {
            // setDropdown("none");
            setNavDrop("");
          }}
          style={{ cursor: "pointer" }}
        >
          Register
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/login"
          className="nav-item nav-link"
          onClick={() => {
            // setDropdown("none");
            setNavDrop("");
          }}
          style={{ cursor: "pointer" }}
        >
          Login
        </NavLink>
      </li>
    </>
  );

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light fixed-top navbar-shrink"
        id="mainNav"
        ref={wrapperRef}
      >
        <div className="container-fluid">
          <Link className="navbar-brand js-scroll-trigger" to="/">
            <FontAwesomeIcon icon={faSign} /> Simple Sign Calls
          </Link>
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            onClick={() => {
              navDrop === "" ? setNavDrop("show") : setNavDrop("");
            }}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className={`collapse justify-content-end navbar-collapse ${navDrop}`}
            id="navbarResponsive"
          >
            <ul className="navbar-nav me-2">{guestLinks}</ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
