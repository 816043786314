import axios from "axios";
import { SET_PLAN, PLAN_FAIL, CANCEL_PLAN } from "./types";
import { setAlert } from "./alert";
import { loadUser } from "./auth";

export const setPlan = (stripeToken, plan, email) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ stripeToken, email, plan });
    const res = await axios.post("/api/stripe/", body, config);

    dispatch({
      type: SET_PLAN,
      payload: res.data,
    });
    dispatch(loadUser());
    dispatch(setAlert("Plan set!", "success"));
  } catch (err) {
    const error = err.response.data.errors;
    if (error) {
      error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: PLAN_FAIL,
      payload: error,
    });
  }
};

export const updatePlan = (plan) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ plan });
    const res = await axios.post("/api/stripe/upgrade", body, config);

    dispatch({
      type: SET_PLAN,
      payload: res.data,
    });
    dispatch(setAlert("Plan updated!", "success"));
    dispatch(loadUser());
  } catch (err) {
    const error = err.response.data.errors;
    if (error) {
      error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: PLAN_FAIL,
      payload: error,
    });
  }
};

export const cancelPlan = () => async (dispatch) => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = null;
  try {
    const res = await axios.post("/api/stripe/cancel", body, config);
    dispatch({
      type: CANCEL_PLAN,
      payload: res.data,
    });
    dispatch(loadUser());
    dispatch(setAlert("Your plan has been cancelled", "success"));
  } catch (err) {
    const error = err.response.data.errors;
    if (error) {
      error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: PLAN_FAIL,
      payload: error,
    });
  }
};

export const updateCard = (stripeToken, email) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ stripeToken, email });
    const res = await axios.post("/api/stripe/card", body, config);

    dispatch({
      type: SET_PLAN,
      payload: res.data,
    });
    dispatch(loadUser());
    dispatch(setAlert("Card updated!", "success"));
  } catch (err) {
    const error = err.response.data.errors;
    if (error) {
      error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: PLAN_FAIL,
      payload: error,
    });
  }
};
