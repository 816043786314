import { SEND_FEEDBACK, SEND_ERROR } from "../actions/types";

const intitialState = {
  sent: false,
  message: ""
};

export default function(state = intitialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEND_FEEDBACK:
      return { ...state, sent: true, message: payload };
    case SEND_ERROR:
      return { ...state, sent: false, message: payload };
    default:
      return state;
  }
}
