import {
  GET_ADMIN_USER,
  GET_ADMIN_SINGLE_USER,
  ADMIN_ERROR,
  CLEAR_ADMIN,
  GET_ADMIN_STATS,
  ADMIN_LOADING,
  ADMIN_CANCEL_USER,
} from "../actions/types";

const initialState = {
  users: [],
  singleUser: {},
  stats: {},
  adminLoading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ADMIN_USER:
      return { ...state, users: payload, adminLoading: false };
    case GET_ADMIN_SINGLE_USER:
    case ADMIN_CANCEL_USER:
      return { ...state, singleUser: payload, adminLoading: false };
    case GET_ADMIN_STATS:
      return { ...state, stats: payload, adminLoading: false };
    case CLEAR_ADMIN:
      return {
        users: [],
        singleUser: {},
        stats: {},
        adminLoading: true,
      };
    case ADMIN_LOADING:
      return { ...state, adminLoading: true };
    case ADMIN_ERROR:
      return { ...state, adminLoading: false };
    default:
      return state;
  }
}
