import React, { Fragment } from "react";
import { Link } from "react-router-dom";
// import { connect } from "react-redux";
import PropTypes from "prop-types";

const Footer = ({ auth }) => {
  const authed = "";

  const guest = (
    <div className="d-flex justify-content-around">
      <div className="p-2">
        <h5>Simple Sign Calls</h5>
        <ul className="list-unstyled">
          <li>
            <a href="https://www.simplesigncalls.com">Home</a>
          </li>
          <li>
            <Link to="/register">Register</Link>
          </li>
          <li>
            <Link to="/login">Login</Link>
          </li>
        </ul>
      </div>
      <div className="p-2">
        {/* <h5>Resources</h5>
        <ul className="list-unstyled">
          <li>
            <Link to="/how-it-works">How It Works</Link>
          </li>
          <li>
            <Link to="/learn-more">Learn More</Link>
          </li>
          <li>
            <Link to="/pricing">Pricing</Link>
          </li>
          <li>
            <Link to="/faq">FAQs</Link>
          </li>
           <li>
            <Link to="/solutions">Solutions</Link>
          </li> 
        </ul> */}
      </div>
      <div className="p-2" />
    </div>
  );
  return (
    <Fragment>
      <hr />
      {auth ? authed : guest}

      <div className="d-flex justify-content-center mt-4">
        &copy; &nbsp;
        <a href="https://www.simplesigncalls.com">SimpleSignCalls.com</a>
        &nbsp; {new Date().getFullYear()}
      </div>
    </Fragment>
  );
};

Footer.propTypes = {
  auth: PropTypes.bool.isRequired,
};

// const mapStateToProps = (state) => ({
//   auth: state.auth,
// });

// export default connect(mapStateToProps, {})(Footer);
export default Footer;
