const prettyPhone = (phone) => {
  return phone
    .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "($2) $3-$4")
    .replace("+", "");
};

const prettyDate = (d, timezone = "EST", format = "m/d/y") => {
  const date = new Date(d);

  let options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  if (format === "m/d/y") {
    options.year = "numeric";
    options.month = "numeric";
    options.day = "numeric";
    return date.toLocaleString("en-US", options);
  }
  if (format === "d/m/y") {
    options.year = "numeric";
    options.month = "numeric";
    options.day = "numeric";
    return date.toLocaleString("en-GB", options);
  }
  if (format === "mmm, d y") {
    options.year = "numeric";
    options.month = "short";
    options.day = "numeric";
    return date.toLocaleString("en-US", options);
  }
};

export { prettyPhone, prettyDate };
