import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import sign from "./sign";
import message from "./message";
import lookup from "./lookup";
import stripe from "./stripe";
import settings from "./settings";
import search from "./search";
import admin from "./admin";

export default combineReducers({
  alert,
  auth,
  message,
  lookup,
  search,
  settings,
  sign,
  stripe,
  admin,
});
