import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import { setPhone } from "../../actions/lookup";
import { createSign } from "../../actions/sign";
import { setAlert } from "../../actions/alert";

const SetUpAccount = ({ user, signs, temp, createSign, setPhone }) => {
  const [forward, setForward] = useState(false);
  const [status, setStatus] = useState({
    status: "Adding Your Phone",
    error: null,
  });
  //set phone number
  useEffect(() => {
    if (temp) {
      if (temp.phone && !user.phone) {
        setPhone(temp.phone);
        setStatus({ status: "Phone Added!", type: null });
      } else {
        setStatus({ status: "Phone Error", error: "phone" });
        setAlert("Error setting up phone number. Try Again", "danger");
        setForward(true);
      }
    } else {
      setForward(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp, user]);

  //then set up first sign
  useEffect(() => {
    if (temp) {
      if (user.phone) {
        if (temp.sign && signs.length < 1) {
          const { code, message, notify, forward, address } = temp.sign;
          createSign({
            code,
            phone: temp.phone,
            message,
            notify,
            forward,
            address,
          });
          setStatus({ status: "Sign Created!", type: null });
        } else {
          setStatus({ status: "Sign Error", error: "sign" });
          setAlert("Error setting up code. Try Again", "danger");
          setForward(true);
        }
      }
    } else {
      setForward(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, temp, signs]);

  //then forward
  useEffect(() => {
    if (user.phone && signs.length > 0) {
      setForward(true);
    }
  }, [user, signs]);

  if (forward) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <div>
      <div className="position-absolute top-50 start-50">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <p>{status.status}</p>
      </div>
    </div>
  );
};

SetUpAccount.propTypes = {
  setPhone: PropTypes.func.isRequired,
  createSign: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  temp: state.auth.temp,
  user: state.auth.user,
  signs: state.sign.signs,
});

export default connect(mapStateToProps, { setPhone, createSign })(SetUpAccount);
