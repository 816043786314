import axios from "axios";
import {
  GET_MESSAGES,
  GET_MESSAGE,
  MESSAGES_ERROR,
  LOADING_MESSAGES,
  CLEAR_SINGLE_MESSAGE,
  CLEAR_MESSAGES,
  ADD_FAKE,
} from "../actions/types";
import { setAlert } from "./alert";

//get all user's messages
export const getMessages = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_MESSAGES });
    const res = await axios.get("/api/messages");

    dispatch({
      type: GET_MESSAGES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: MESSAGES_ERROR,
    });
  }
};

//Get all user's messages by type - texts or calls
export const getMessagesByType = (type) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_MESSAGES });

    const res = await axios.get(`/api/messages/${type}`);

    dispatch({
      type: GET_MESSAGES,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: MESSAGES_ERROR,
    });
  }
};

//get message by id
export const getMessageById = (messageId) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_MESSAGES,
    });
    const res = await axios.get(`/api/messages/${messageId}`);

    dispatch({
      type: GET_MESSAGE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: MESSAGES_ERROR,
    });
  }
};

export const getMessagesByCode = (code) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_MESSAGES });

    const res = await axios.get(`/api/messages/code/${code}`);

    dispatch({
      type: GET_MESSAGES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: MESSAGES_ERROR,
    });
  }
};

export const clearSingleMessage = () => (dispatch) => {
  dispatch({ type: CLEAR_SINGLE_MESSAGE });
};

export const clearMessages = () => (dispatch) => {
  dispatch({ type: CLEAR_MESSAGES });
};

export const addFake = (code, type, to) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ code, type, to });

  try {
    const res = await axios.post("/api/incoming/fake", body, config);
    dispatch({
      type: ADD_FAKE,
      payload: res.data,
    });
    dispatch(setAlert(type + " added!"), "success", 5000);
    dispatch(getMessages());
  } catch (err) {
    const errors = err.response.data.errors;
    console.log(err);

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};
