import {
  SET_PLAN,
  CHANGE_PLAN_NEEDED,
  CANCEL_PLAN,
  PLAN_FAIL,
  CLEAR_STRIPE
} from "../actions/types";

const initialState = {
  plan: null,
  loading: true,
  needsAttention: false
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PLAN:
      return {
        ...state,
        plan: payload,
        loading: false,
        needsAttention: false
      };
    case CLEAR_STRIPE:
      return {
        ...state,
        plan: null,
        loading: false,
        needsAttention: false
      };
    case CHANGE_PLAN_NEEDED:
      return {
        ...state,
        plan: payload,
        loading: false,
        needsAttention: true
      };
    case CANCEL_PLAN:
    case PLAN_FAIL:
      return {
        ...state,
        plan: null,
        loading: false,
        needsAttention: false
      };
    default:
      return state;
  }
}
