import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { solutions } from "../../utlis/solutions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

const Solution = ({ match }) => {
  const slug = match.params.slug;
  const solution = solutions.find((solutions) => solutions.slug === slug);
  return (
    <Fragment>
      <Helmet>
        <title>
          {solution
            ? solution.title + " - Simple Sign Calls"
            : "Solution - Simple Sign Calls"}
        </title>
      </Helmet>
      <Link to="/solutions">
        <FontAwesomeIcon icon={faArrowLeft} /> Back To Solutions
      </Link>
      <h1 className="display-4">{solution.title}</h1>
      <p dangerouslySetInnerHTML={{ __html: solution.discription }} />
      <p>
        Learn more on how to take advantage of your sign calls using our{" "}
        <Link to="/">real estate text rider system</Link>.
      </p>
    </Fragment>
  );
};

export default Solution;
