import React from "react";
import { Link } from "react-router-dom";

const LearnMore = () => {
  return (
    <div className="container">
      <h1 className="display-4">Learn More</h1>
      {/* <div className="d-flex justify-content-center">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/0WVDKZJkGlY"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div> */}
      {/* <div className="row">
        <div className="col-lg-8 mx-auto text-center mt-5">
          <Link
            className="btn btn-primary btn-xl js-scroll-trigger shadow m-3"
            to="/register"
          >
            Start Free Trial
          </Link>
        </div>
      </div> */}
      <h1 className="display-6">Step 1</h1>
      <p className="lead">Get a Custom, Local Phone Number</p>
      <p>
        You choose your phone number. Simply search by area code and choose your
        phone number.
      </p>
      <hr />
      <h1 className="display-6">Step 2</h1>
      <p className="lead">Create Your Code</p>
      <p>Create a code to be text. This could be '1234' or 'home'. </p>
      <hr />
      <h1 className="display-6">Step 3</h1>
      <p className="lead">Customize The Auto-Response</p>
      <p>
        Customize the response that gets autosent When someone texts your code
        to your number. Check out exactly how it works,{" "}
        <a href="sms:+13852904140&body=demo">Text DEMO to (385) 290-4140.</a>
      </p>
      <hr />
      <h1 className="display-6">Step 4</h1>
      <p className="lead">Set the Notifications</p>
      <p>
        Set up to 5 email addresses that get a notification that that code was
        text. It works like caller ID grabbing the name from the phone number.
        Instantly get an email with this info so you can collect leads and
        follow up.
      </p>
      <hr />
      <h1 className="display-4">Step 5</h1>
      <p className="lead">Market It</p>
      <p>
        Now it's time to get people to call and text your number. Print it on
        your for sale signs, put it on flyers, make it the call to action on
        Craigslist, and anywhere else you can think!
      </p>
      <hr />
      <h1 className="display-6">Step 6</h1>
      <p className="lead">Update It</p>
      <p>
        When the house sells, simply update the auto-response (step 3) and put
        the code on a new listing.
      </p>
      <div>
        <Link to="/register" className="d-grid gap-2 col-6 mx-auto pt-3 pb-5">
          <button className="btn btn-lg btn-primary shadow p-3 rounded ">
            Get Started Now
          </button>
        </Link>
        <p className="text-center text-secondary">
          No Contracts - Cancel Anytime
        </p>
      </div>
      <p className="lead mt-5">
        Still want more? See <Link to="/faq">Frequently Asked Questions</Link>
      </p>
    </div>
  );
};

export default LearnMore;
